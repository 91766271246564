import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { TextField, Button, Typography } from '@mui/material'
import useEvents from './useEvents'

const EventForm = ({ onSubmit, event }) => {
    const [title, setTitle] = useState('')
    const [date, setDate] = useState('')
    const [location, setLocation] = useState('')
    const [description, setDescription] = useState('')

    const { getNewKey, updateEntities } = useEvents()

    const handleSubmit = (e) => {
        e.preventDefault()
        if (title && date && location && description) {

            const updates = {}

            const ev = { title, date, location, description, addedAt: (new Date()).toISOString().substring(0,10) }

            const uid = event?.uid || getNewKey()

            updates[`events/${uid}`] = ev

            updateEntities(updates, () => {
                onSubmit(ev)
                setTitle('')
                setDate('')
                setLocation('')
                setDescription('')
            })

        }
    }

    useEffect(() => {

        if (event) {
            const { title, date, location, description } = event
            setTitle(title)
            setDate(date)
            setLocation(location)
            setDescription(description)
        }

    }, [])


    return (
        <form onSubmit={handleSubmit}>
            <Typography variant='h6'>Ajouter ou modifier un évènement</Typography>
            <TextField
                variant='filled'
                label="Title"
                value={title}
                fullWidth
                onChange={(e) => setTitle(e.target.value)}
                margin="normal"
            />
            <TextField
                label="Date"
                type="date"
                variant='filled'
                fullWidth
                value={date.replace(/t| /i, "").substring(0, 10) || (new Date()).toISOString().substring(0, 10)}
                onChange={(e) => setDate(e.target.value)}
                margin="normal"
            />
            <TextField
                variant='filled'
                label="Location"
                value={location}
                fullWidth
                onChange={(e) => setLocation(e.target.value)}
                margin="normal"
            />
            <TextField
                label="Description"
                value={description}
                variant='filled'
                multiline
                minRows={3}
                fullWidth
                onChange={(e) => setDescription(e.target.value)}
                margin="normal"
            />
            <Button variant="contained" color="primary" type="submit">
                Enregistrer
            </Button>
        </form>
    )
}



EventForm.propTypes = {
    event: PropTypes.object.isRequired
}

export default EventForm
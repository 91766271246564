import axios from "axios";

export const sendNotification = (destination) => {

    const m = {
        notification: {
            body: "Un évènement vient d'être ajouté par le parti.",
            title: "RHDP Diaspora"
        },
        priority: "normal",
        data: {
            click_action: "FLUTTER_NOTIFICATION_CLICK",
            source: "president",
            status: "done",
            rideId: "-NLlzqD1Tlu-mgZbv2BQ",
            time: "15:10"
        },
        to: destination,
        direct_boot_ok: true
    }

    var config = {
        method: 'post',
        url: 'https://fcm.googleapis.com/fcm/send',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer AAAA_0ArV4k:APA91bEUx_3wfLPt851i07jcXguMin0xnyN6WoHgkUJgrSlZwiqetftxs9V9HRi7zyC-NLNHwdtONscSCjaXfXx0aRW5HQPXVejhEwD9ddoODaZ45ax7cmeKQmJBYC8rmtTQCQFjzS55'
        },
        data: JSON.stringify(m)
    }

    axios(config)



}
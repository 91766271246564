import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { CalendarMonthOutlined, DataObject, LinkOutlined, MoreOutlined, NextPlanOutlined, PlaceOutlined, SubjectOutlined } from '@mui/icons-material';
import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';


export default function MeetingMessage(props) {
    const {
        meetingObject,
        adresse,
        link,
        objective,
        meetingDate,
        more,

    } = props.meeting
    return (
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            <ListItem>
                <ListItemAvatar>
                    <Avatar>
                        <SubjectOutlined />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Objet" secondary={meetingObject} />
            </ListItem>
            <ListItem>
                <ListItemAvatar>
                    <Avatar>
                        <PlaceOutlined />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Adresse" secondary={
                    !adresse ? <small>(non définie)</small> : adresse
                } />
            </ListItem>
            <ListItem>
                <ListItemAvatar>
                    <Avatar>
                        <LinkOutlined />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Lien" secondary={
                    !link ? <small>(pas accessible en ligne)</small> : (
                        <Link color={'secondary'} href={`${link}`}>{link}</Link>
                    )
                } />
            </ListItem>
            <ListItem>
                <ListItemAvatar>
                    <Avatar>
                        <CalendarMonthOutlined />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Date et heure" secondary={moment(meetingDate).format('LLLL')} />
            </ListItem>
            <ListItem>
                <ListItemAvatar>
                    <Avatar>
                        <NextPlanOutlined />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Objectif de la réuinion" secondary={objective || "--"} />
            </ListItem>
            <ListItem>
                <ListItemAvatar>
                    <Avatar>
                        <MoreOutlined />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Plus d'infos" secondary={more || "--"} />
            </ListItem>
        </List>
    );
}
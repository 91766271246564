import { useState } from "react"
import useFetcher from "../../reacthook/useFetcher"

export default function useVideos(props) {

    const wait = props?.wait

    const [url] = useState(`/medias`)

    const transformer = (snapshot) => {
        let r = []

        Object.entries(snapshot).forEach((el, id) => {
            const [uid, video] = el

            r.push({ id, uid, ...video })
        });
        return r
    }

    const { entities, ...rest } = useFetcher({ url, transformer, wait })

    return { videos: entities, ...rest }
}
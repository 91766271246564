import { Paper, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import MultilineTextFields from './MessageFields'

function Message() {


  useEffect(() => {    
    document.title = "Envoyer des notifications"
  })
  
  return (
    <>
      <Typography component={'h1'} variant="h4">Envoyer des notifications</Typography>
      <Paper sx={{minHeight:'80vh', width:"100%", justifyContent:"center", display:"flex"}}>
        <MultilineTextFields/>
      </Paper>

    </>
  )
}

export default Message
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Autocomplete, Box, DialogActions, TextField } from '@mui/material';

import ReactPlayer from 'react-player/youtube'

const emails = ['userurlOrId@gmail.com', 'user02@gmail.com'];

export default function NewVideoDialog(props) {
    const { onClose, open, add } = props;

    const [urlOrId, setUrlOrId] = useState('')
    const [url, setUrl] = useState('')
    const [idVideo, setIdVideo] = useState('')

    const [titre, setTitle] = useState('')
    const [isValdVideo, setIsValdVideo] = useState(false)
    const [category, setCategory] = useState('CYBERMILITANT')

    const handleClose = () => {
        setTitle('')
        setIsValdVideo(false)
        setUrlOrId("")
        setUrl("")

        onClose()
    };
    const addVideo = () => {
        add({
            titre,
            url,
            category,
            idVideo,
            isLive: category === 'LIVE',
            addedAt: (new Date()).toISOString(),
        })

        handleClose()
    };

    return (
        <Dialog fullWidth onClose={handleClose} open={open}>
            <DialogTitle>Nouvelle vidéo</DialogTitle>
            <Box sx={{ p: 2 }}>

                <ReactPlayer width={"100%"} height={"50%"} onReady={(player) => {

                    if (player.getDuration()) {

                        const iframes = document.getElementsByTagName('iframe')

                        const iframe = iframes[2] || iframes[1] || iframes[0]

                        setTitle(iframe.title)

                        setIdVideo(url.replace("https://youtu.be/", "").replace("https://www.youtube.com/watch?v=", ""))

                        setIsValdVideo(true)
                    }

                }}
                    onError={(error) => {
                        console.log(error)
                    }} url={url} />


                {!isValdVideo ? (

                    <TextField
                        autoFocus
                        error={!urlOrId.trim()}
                        helperText={"Entrez l'url d'une vidéo Youtube."}
                        margin="dense"
                        value={urlOrId}
                        placeholder="https://www.youtube.com/watch?v=xxx000XXX-X"
                        onChange={(event) => {
                            setUrlOrId(event.target.value)
                            setTitle('')
                            setIsValdVideo(false)
                        }}
                        label="URL"
                        fullWidth
                        variant="outlined"
                    />
                ) : (

                    <>
                        <TextField
                            autoFocus
                            margin="dense"
                            error={!titre?.trim()}
                            helperText={titre?.trim() ? "" : "Le titre de la vidéo ne doit pas être vide."}
                            value={titre}
                            label='Titre de la vidéo'
                            onChange={(event) => {
                                setTitle(event.target.value)
                            }}
                            fullWidth
                            variant="outlined"
                        />
                        <Autocomplete
                            margin="dense"
                            sx={{ my: 1 }}
                            options={[
                                'PAYS',
                                'LIVE',
                                'DIASPORA',
                                'CYBERMILITANT',
                            ]}
                            onChange={(event, v) => {
                                setCategory(v || 'CYBERMILITANT')
                            }}
                            value={category}
                            renderInput={(params) => <TextField {...params} label="Catégorie" />}
                            isOptionEqualToValue={(c, v) => c === v}
                            fullWidth
                            variant="outlined"
                        />
                    </>
                )}


            </Box>

            <DialogActions>
                <Button onClick={handleClose}>Annuler</Button>

                <Button onClick={() => {
                    setUrl(urlOrId)
                }} disabled={!urlOrId.trim()} sx={{ display: !isValdVideo ? 'initial' : 'none' }} >Rechercher</Button>
                <Button onClick={() => {
                    addVideo()
                }} sx={{ display: isValdVideo ? 'initial' : 'none' }}>Ajouter</Button>
            </DialogActions>

        </Dialog>
    );
}

NewVideoDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};
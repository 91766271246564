import { child, get, getDatabase, ref } from "firebase/database";
import { createContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { parseMembersToList, parseMessagesToList } from "../pages/canal/Canal";

export const CanalContext = createContext()

export function CanalContextProvider(props) {

    const db = getDatabase()
    const [messages, setMessages] = useState([])
    const [loading, setLoading] = useState(true)
    const [uid, setUid] = useState(null)

    const [canalInfos, setCanalInfos] = useState({})

    const [canaux, setCanaux] = useState([])


    const fetchCanaux = () => {
        setLoading(true)
        get(child(ref(getDatabase()), `/canaux`)).then((snapshot) => {

            if (snapshot.exists()) {

                const data = snapshot.val()
                const keys = Object.keys(snapshot.val())
                let r = []

                for (let i = 0; i < keys.length; i++) {
                    const uid = keys[i]

                    let { name, members, messages } = data[uid];

                    members = parseMembersToList(members)
                    messages = parseMessagesToList(messages)

                    r.push({ name, members, messages, uid })

                }
                setCanaux(r)
            } else {
                console.log("No data available");
            }
        }).catch((error) => {
            console.error(error);
        }).finally(() => {
            setLoading(false)
        })
    }

    const fetchAllCanaux = () => fetchCanaux()

    useEffect(() => {
        setCanalInfos({})

        refreshCanalInfos()

    }, [uid])



    const refreshCanalInfos = async () => {

        setLoading(true)
        const dbRef = ref(db);
        await get(child(dbRef, `/canaux/${uid}`)).then((snapshot) => {

            if (snapshot.exists()) {

                let { name, uid, members, messages } = snapshot.val()

                members = parseMembersToList(members)
                messages = parseMessagesToList(messages)

                setMessages(messages)


                console.log({ name, members, messages })

                setCanalInfos(
                    {
                        name, uid, members, messages
                    }
                )
            } else {
                console.log("No data available");
            }
        }).catch((error) => {
            console.error(error);
        }).finally(() => {
            setLoading(false)
        })

    }

    const refresh = () => refreshCanalInfos()
    const upDateCanal = uid => { setUid(uid) }


    return (
        <CanalContext.Provider value={{ canalInfos, loading, refresh, canaux, upDateCanal, fetchAllCanaux, uid }}>
            <>
                {props.children}
            </>
        </CanalContext.Provider>
    )
}



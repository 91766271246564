import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { child, getDatabase, push, ref, update } from 'firebase/database';
import { useParams } from 'react-router-dom';
import { MenuItem } from '@mui/material';

export default function DeleteCanalDialog(props) {
    const [open, setOpen] = React.useState(false);
    const [name, setName] = React.useState('');

    const { uid } = useParams()

    const { onClose } = props

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (success = false) => {

        onClose(success)
        setOpen(false);
    };

    const deleteCanal = () => {
        let updates = {};

        updates[`canaux/${uid}`] = null

        update(ref(getDatabase()), updates).then(() => {
            handleClose(true)
        }).catch((error) => {
            console.error(error);
            handleClose(false)
        })
    }

    return (
        <div>
            <MenuItem onClick={handleClickOpen}>Supprimer le canal </MenuItem>

            <Dialog open={open} onClose={() => { handleClose(false) }}>
                <DialogTitle>Renommer</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Voulez-vous vraiment supprimer ce canal de communication ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { handleClose(false) }}>Annuler</Button>
                    <Button onClick={() => { deleteCanal() }}>Confirmer</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Stack from '@mui/material/Stack';
import moment from 'moment';

export default function MeetingShedule(props) {
    const [value, setValue] = useState(moment().add({ day: 1 }));

    useEffect(() => {
        props.onChange(value.isValid() ? value : '')
    }, [value])


    return (
        <Stack spacing={3}>
            <DateTimePicker
                renderInput={(params) => <TextField required type={'date'} variant='standard' {...params} />}
                label="Date et heure de la réunion"
                value={value}
                onChange={(newValue) => {
                    setValue(newValue);
                }}

                minDate={moment()}
                minTime={value?.isSameOrBefore(moment().add({ minutes: 10 })) ? moment().add({ minutes: 10 }) : undefined}
            />
        </Stack>
    );
}

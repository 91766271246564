import { Box, Typography } from '@mui/material'
import React from 'react'
import { alignCenterStyle } from '../../style/css/css'

export default function Error404NotFound() {
  return (
    <>


      <Box sx={{ ...alignCenterStyle }}>
        <Typography variant="h2" >
          404
        </Typography>
      </Box>
      <Box sx={{ ...alignCenterStyle, marginTop: 0 }}>
        <Typography variant="h6" >
          Page non trouvée.
        </Typography>
      </Box>
    </>
  )
}

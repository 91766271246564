import { MarkerF } from "@react-google-maps/api";
import { useEffect, useState } from "react";

export default function Marker(props) {

    const { coords, title, label, onClick, showLabel } = props

    const [labelText, setLabelText] = useState('');

    useEffect(() => {
        if (showLabel) {
            setLabelText(label)
        } else {
            setLabelText('')
        }
    }, [showLabel]);

    const handleClick = () => {
        if (onClick) {
            onClick()
        }
    }

    return (
        coords ? (

            <MarkerF
                label={props.showLabel ? label : null}
                title={title}
                draggable={props.onDraged !== undefined}
                onDragEnd={(e) => {
                    if (props?.onDraged) {
                        props.onDraged({
                            lng: e.latLng.lng(),
                            lat: e.latLng.lat(),
                        })
                    }
                }}
                position={coords}
                icon={"/marker.png"}
                onClick={() => { handleClick() }} />
        ) : <></>
    )
}
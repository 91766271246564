
import PropTypes from 'prop-types'
import React from 'react';
import Avatar from '@mui/material/Avatar';

function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name) {

    if(!name){
        name = "#"
    }

    const first = name.split(' ')[0][0]
    const sec = name.split(' ')[1]

    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${first}${sec ? sec[0] : ''}`,
    };
}

export function StringColoredAvatar(props) {
    const { name } = props
    return (
        <Avatar {...stringAvatar(name)} />
    );
}


StringColoredAvatar.propTypes = {
    name: PropTypes.string.isRequired
}

export default StringColoredAvatar

import React, { useContext, useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CanalContext } from '../../context/CanalContext';
import palette from '../../style/palette';
import { PropagateLoader } from 'react-spinners';
import { Box, Card } from '@mui/material';
import PendingSpinner from '../../component/PendingSpinner';
import { child, get, getDatabase, ref } from 'firebase/database';
import moment from 'moment';

export default function ResponsesAccordions(props) {
    const [expanded, setExpanded] = useState(false);

    // const handleChange = (panel) => (event, isExpanded) => {
    //     setExpanded(isExpanded ? panel : false);
    // };

    const [responses, setResponses] = useState([])
    const { canalInfos, uid, loading } = useContext(CanalContext)

    const [pending, setPending] = useState(false)


    const { messageUid, accordionId, handleAccordionChange } = props

    useEffect(() => {
        if (accordionId === messageUid) {
            fetchResponses()
        }
    }, [accordionId])

    const fetchResponses = async () => {
        setPending(true)

        const dbRef = ref(getDatabase());
        await get(child(dbRef, `/messagesResponses/${uid}/${messageUid}/responses`)).then((snapshot) => {
            let responses = []
            if (snapshot.exists()) {

                const data = snapshot.val()

                Object.keys(data).forEach(async (el) => {
                    await get(child(dbRef, `/militants/${el}/fullname`)).then((snap) => {
                        if (snap.exists()) {
                            const author = `${snap.val()} (${el})`
                            const reply = data[el]['reply']
                            responses.push({ author, reply })
                        }
                    })
                })

            } else {
                console.log("No data available");
            }

            setResponses(responses)
        }).catch((error) => {
            console.error(error);
        }).finally(() => {
            setPending(false)
        })
    }


    return (
        <Accordion elevation={0} expanded={messageUid === accordionId} onChange={() => { handleAccordionChange(messageUid !== accordionId ? messageUid : '') }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon color={'secondary'} />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
            >
                <Typography color={'secondary'}>
                    Afficher les reponses
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                {
                    pending ? (
                        <PendingSpinner />
                    ) : (
                        responses.length === 0 ? (
                            <Typography textAlign={'center'}>(Aucune réponse)</Typography>
                        ) : (
                            responses.map((el, index) => {
                                return <ResponseMessageCardView key={index} reply={el.reply} author={el.author} date={el.date} />
                            })
                        )
                    )
                }
            </AccordionDetails>
        </Accordion>
    );
}




export function ResponseMessageCardView(props) {

    const { reply, author, date } = props

    const formater = (reply) => {
        return reply.split('\n').map((el, index) => {
            return <Typography variant='body1' key={index}>{el}</Typography>
        })
    }


    return (
        <Card variant="outlined" sx={{ p: 1 }}>
            {!author ? <></> : (
                <Typography variant='caption' mt={1} component={'p'} >{author}</Typography>
            )}
            {formater(reply)}
            {!date ? <></> : (
                <Typography variant='caption' mt={1} textAlign={'end'} component={'p'} >{moment(date.replace(' ', 'T')).calendar()}</Typography>
            )}
        </Card>
    )
}

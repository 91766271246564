import { useState } from "react"
import useFetcher from "../../reacthook/useFetcher"

export default function useNotificationHistory(props) {

    const wait = props?.wait

    const [url] = useState(`/notificationHistory`)

    const transformer = (snapshot) => {
        let r = []

        Object.entries(snapshot).forEach((el, id) => {
            const [uid, notificationHistory] = el
            r.push({ id, uid, ...notificationHistory })
        })
        return r
    }

    const { entities, ...rest } = useFetcher({ url, transformer, wait })

    return { notificationHistory: entities, ...rest }
}
import { getDatabase, ref, child, get } from "firebase/database";
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Avatar, Paper, Typography } from '@mui/material';
import StringColoredAvatar from '../component/StringColoredAvatar';
import MaterialDataGrid from "../component/MaterialDataGrid";
import { Spinner } from "../component/Cover";
import { GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import { PropaneRounded } from "@mui/icons-material";

const columns = [
    {
        field: 'string', headerName: 'Avatar',
        disableExport: true, filterable: false,
        width: 64,
        renderCell: ({ row }) => {
            return <StringColoredAvatar name={row.fullName} />
        }
    },
    {
        field: 'fullName',
        headerName: 'Nom et prenoms',
        sortable: true,
        flex: 1,
        minWidth: 150,
    },
    {
        field: 'phone',
        headerName: 'Contacts',
        flex: 1,
        minWidth: 150,
        sortable: false,
    },
];

export default function Contacts(props) {

    const [rows, setRows] = useState([])
    const [loading, setLoading] = useState(true)
    const [hasError, setHasError] = useState(false)

    const { filter } = props

    useEffect(() => {
        const dbRef = ref(getDatabase());
        get(child(dbRef, `/militants`)).then((snapshot) => {

            setLoading(false)
            if (snapshot.exists()) {
                const data = snapshot.val()
                const keys = Object.keys(snapshot.val())
                let r = []

                for (let i = 0; i < keys.length; i++) {

                    const userUid = keys[i]
                    const { fullname, phonenumber, id, fcmToken } = data[userUid];

                    r.push({ id: i, phone: phonenumber, fullName: fullname, fcmToken, uid: id, userUid })

                }
                if (filter) {

                    if (filter.length !== 0) {

                        r = r.filter((el) => {

                            let result = true

                            filter.forEach(element => {
                                result = el.userUid !== element.uid
                            })
                            return result
                        })

                    }
                }

                setRows(r);
                if (props?.onData) {
                    props?.onData(r)
                }

                console.log(snapshot.val());
            } else {
                console.log("No data available");
            }
        }).catch((error) => {
            setLoading(false)
            setHasError(true)
            console.error(error);
        });
    }, [])


    if (loading) {

        return (
            <Spinner />
        )
    }


    if (hasError) {

        return (
            <Typography  >
                Erreur! Actualisez la page SVP.
            </Typography>
        )
    }



    return (

        <>
            <Typography variant="h6" >
                {props?.title || "Liste des numéros de téléphone."}
            </Typography>

            <Paper>
                <Box sx={{ height: 450, width: '100%', mt: 2 }}>

                    <MaterialDataGrid onSelectionChange={props.onSelectionChange} control={props.control} data={{ rows, columns }} />
                </Box>
            </Paper>
        </>

    );
}





function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}
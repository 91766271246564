import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button, FormControl, FormControlLabel, InputLabel, Link, MenuItem, Select, Stack, Switch, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import Contacts from '../Contacts';
import SendIcon from '@mui/icons-material/Send';
import DomAlert from '../../component/DomAlert';
import AppAlert, { ALERT_TYPE } from '../../core/AppAlert';
import { APP_STATE } from '../../core/kernel';
import { AppContext } from '../../context/AppContext';
import axios from 'axios';
import useNotificationHistory from './useNotificationsHistory';
import { MessageCardView } from '../canal/MessagePanel';
import { NotificationCardView } from './NotificationCardView';

export default function MultilineTextFields() {

    const [everybody, setEverybody] = useState(true)
    const [alert, setAlert] = useState(null)
    const [priority, setPriority] = useState('normal')
    const [message, setMessage] = useState('')
    const [title, setTitle] = useState('')
    const [selection, setSelection] = useState([])
    const [destinations, setDestinations] = useState([])
    const [data, setData] = useState([])

    const [showHistory, setShowHistory] = useState(false)

    const histo = useNotificationHistory()

    const { handleAppState } = useContext(AppContext)


    useEffect(() => {
        let d = []
        selection.forEach((el) => {
            d.push(data[el].fcmToken)
        })
        setDestinations(d)
    }, [selection])

    useEffect(() => {
        console.log(histo.notificationHistory)
    }, [histo])


    useEffect(() => {
        if (everybody) {
            setAlert(null)

        } else {
            // handleAppState(APP_STATE.alert, new AppAlert("La fonctionnalité d'envoi ciblé est en dev.", ALERT_TYPE.warning, 666))
            setAlert(new AppAlert("Selectionnez les contacts auxquels vous voulez envoyer le message puis cliquez sur envoyer. Vous pouvez utiliser les filtres pour cibles des catégories de membre."))
        }

    }, [everybody])

    function resetFields() {
        setMessage('')
        setTitle('')
        setPriority('normal')
        setEverybody(true)
    }

    const buildMessage = (destination) => {

        const m = {
            notification: {
                body: "Vous avez reçu un message du parti",
                title: "RHDP Diaspora"
            },
            priority: priority,
            data: {
                click_action: "FLUTTER_NOTIFICATION_CLICK",
                source: "president",
                title: title,
                body: message,
                status: "done",
                rideId: "-NLlzqD1Tlu-mgZbv2BQ",
                time: "15:10"
            },
            to: destination,
            direct_boot_ok: true
        }

        var config = {
            method: 'post',
            url: 'https://fcm.googleapis.com/fcm/send',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer AAAA_0ArV4k:APA91bEUx_3wfLPt851i07jcXguMin0xnyN6WoHgkUJgrSlZwiqetftxs9V9HRi7zyC-NLNHwdtONscSCjaXfXx0aRW5HQPXVejhEwD9ddoODaZ45ax7cmeKQmJBYC8rmtTQCQFjzS55'
            },
            data: m
        }

        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));

                const updates = {}
                const key = histo.getNewKey()

                updates[`${histo.url}/${key}`] = {
                    message: {
                        content: message, title, date: (new Date()).toISOString()
                    }, destinations: selection
                }

                histo.updateEntities(updates)
            })
            .catch(function (error) {
                console.log(error);
            });



    }

    async function send() {
        if (message && priority && title) {
            handleAppState(APP_STATE.alert, new AppAlert("Envoi en cours...", ALERT_TYPE.info, null, 666))

            const dest = everybody ? ['/topics/allmilitants'] : destinations

            dest.forEach(async (destination, index) => {
                buildMessage(destination)
            })

            handleAppState(APP_STATE.alert, new AppAlert("Message envoyé.", ALERT_TYPE.success, null, 666))
            resetFields()
        } else {
            handleAppState(APP_STATE.alert, new AppAlert(`Votre message doit avoir un titre, un contenu et un ou plusieurs destinaires.`, ALERT_TYPE.error))
        }
    }


    return (
        <Box>

            <Box>

                <Link href='#' onClick={(event) => {
                    event.preventDefault()
                    setShowHistory(!showHistory)
                }}>{showHistory ? "Masquer l'historique" : "Afficher l'historique"}</Link>

                {
                    !showHistory || histo.notificationHistory.length === 0 ? < ></> : (
                        <Stack direction={'column'} spacing={2} width="100%">
                            {histo.notificationHistory.map((el, index) => {
                                return (
                                    <NotificationCardView
                                        text={el.message.content}
                                        title={el.message.title}
                                        date={el.message.date}
                                        canReply={false}
                                        destinations={el.destinations}
                                    />
                                )
                            })}
                        </Stack>
                    )
                }
            </Box>

            <Box
                component="form"
                display={'block'}
                noValidate
                autoComplete="off"
                onSubmit={(event) => {
                    event.preventDefault()
                    send()
                }}
                sx={{ px: 1, maxWidth: 700.666 }}

            >
                <Typography variant='h6'>Nouvelle notification</Typography>

                <TextField
                    id="outlined-multiline-flexible"
                    label="Titre"
                    fullWidth
                    value={title}
                    onChange={(event) => {
                        setTitle(event.target.value)
                    }}
                    sx={{ my: 2 }}
                    maxRows={4}
                />

                <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel id="demo-simple-select-label">Priorité</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={priority}
                        label="Priorité"
                        onChange={(event) => {
                            setPriority(event.target.value)
                        }}
                    >
                        <MenuItem value={'normal'}>Normal</MenuItem>
                        <MenuItem value={'high'}>Elevée</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    id="outlined-multiline-static"
                    label="Message"
                    fullWidth
                    multiline
                    minRows={4}
                    value={message}
                    onChange={(event) => {
                        setMessage(event.target.value);
                    }}
                    placeholder="Saisissez le contenu de votre message ici."
                />
                <FormControlLabel sx={{ my: 2 }} control={<Switch onChange={() => setEverybody(!everybody)} checked={everybody} />} label=" Envoyer à tous les membres" />
                {everybody ? '' : (<><DomAlert appAlert={alert} /> <Contacts title="Destinataires" onData={setData} onSelectionChange={setSelection} control /></>)}

                <Box sx={{ my: 2, display: 'flex', justifyContent: 'end' }}>
                    <Button type='submit' variant="contained" endIcon={<SendIcon />}>
                        Envoyer
                    </Button>
                </Box>
            </Box>
        </Box>

    );
}

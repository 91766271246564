const breakpoints = {
    values: {
        mobile: 0,
        tablet: 640,
        laptop: 1024,
        desktop: 1200,
        xdesktop:1536,
        xs: 0,
        sm: 640,
        md: 1024,
        lg: 1200,
        xl:1536,
    },
}

export default breakpoints;
import React, { useEffect } from 'react';
import PropTypes from 'prop-types'
import AppAlert from '../core/AppAlert';
import { Alert } from '@mui/material';


function DomAlert(props) {

    const [isVisible, setIsVisible] = React.useState(false);





    useEffect(() => {
        setIsVisible(!props?.appAlert?.isInternal)
    }, [props.appAlert])

    if (!props.appAlert) {
        return ""
    }
    const { message, type } = props.appAlert


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setIsVisible(false);
    }


    if (message && isVisible) {
        return (<Alert onClose={handleClose} severity={type} sx={{ m: 0 }}>
            {message}
        </Alert>
        )
    }

    return (
        ""
    )
}

DomAlert.propTypes = {
    appAlert: PropTypes.instanceOf(AppAlert)
}

export default DomAlert

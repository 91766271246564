import React, { useState, useEffect, useContext } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import ImageField from '../../component/FormImages';
import ProjectDatePiker from './ProjectDatePiker';
import useProjects from './useProjects';
import { useNavigate } from 'react-router-dom';
import { AppContext } from "../../context/AppContext";
import { APP_STATE } from '../../core/kernel';
import AppAlert, { ALERT_TYPE } from '../../core/AppAlert';
import GoogleMapView from './map/GoogleMapView';
import axios from 'axios';

export default function ProjectForm(props) {
    const [name, setName] = useState('')
    const [isDone, setIsDone] = useState(false)
    const [date, setDate] = useState('')
    const [adresse, setAdresse] = useState('')
    const [details, setDetails] = useState('')
    const [localization, setLocalization] = useState('')
    const [images, setImages] = useState([])

    const [city, setCity] = useState('')
    const [coords, setCoords] = useState({})
    const [description, setDescription] = useState('')

    const [uid, setUid] = useState(props.uid)



    const { defaultData } = props
    const { getNewKey, updateEntities, url } = useProjects()

    const navigate = useNavigate()
    const { handleAppState, toast, ping } = useContext(AppContext)



    useEffect(() => {

        if (defaultData && uid) {
            const { name,
                isDone,
                date,
                adresse,
                localization,
                images, details } = defaultData

            setName(name || '')
            setIsDone(isDone || '')
            setDate(date || '')
            setAdresse(adresse || '')
            setLocalization(localization || '')
            setImages(images || '')
            setDetails(details || '')
        }

    }, [])



    const handleSubmit = (event) => {
        event.preventDefault();

        const k = uid || getNewKey()
        let updates = {}

        const onSuccess = () => {

            handleAppState(APP_STATE.alert, new AppAlert(`Projet ajouté`, ALERT_TYPE.success))
            sendMessage(
                {
                    message: description, title: name, priority: 'normal',
                    notificationTitle: "Un nouveau projet vient d'être ajouté.",
                    notificationBody: "Un nouveau projet vient d'être ajouté.",
                }, '/topics/allmilitants')
            navigate(-1, { relative: true })
        }
        const onFail = (e) => {
            console.log("error", e);
            handleAppState(APP_STATE.alert, new AppAlert(`Nous n'avons pas pu ajouté le projet. Réessayez SVP.`, ALERT_TYPE.error))

        }

        if (name && city && coords && date) {
            updates[`${url}/${k}`] = { name, isDone, city, coords, date, adresse, localization: { longitude: coords.lng, latitude: coords.lat }, images: images || null }

            updateEntities(updates, onSuccess, onFail)
        } else {
            handleAppState(APP_STATE.alert, new AppAlert(`Remplissez tous les champs obligatoires avant de soumettre..`, ALERT_TYPE.error))
        }



    };


    return (
        <Box sx={{ maxWidth: 666, bgcolor: 'background.paper', display: 'flex', p: 1, mx: "auto" }}>
            <Box onSubmit={handleSubmit} component="form" sx={{ width: "100%" }}>
                <Typography variant='h4'>Créer un nouveau projet</Typography>
                <TextField
                    label="Nom du projet"
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    fullWidth
                    sx={{ my: '1rem' }}
                    required
                />
                <ProjectDatePiker onChange={(value) => {
                    if (value) {
                        setDate(value.toISOString())
                    } else {
                        setDate('')
                    }
                }} />
                <TextField
                    label="Ville"
                    value={city}
                    onChange={(event) => setCity(event.target.value)}
                    fullWidth
                    sx={{ my: '1rem' }}
                />
                <GoogleMapView
                    coords={coords || null}
                    onAdresseChange={
                        (e) => { console.log(e) }
                    }

                    onCoordsChange={(coords) => {
                        setCoords(coords)
                    }} />
                <TextField
                    label="Description"
                    multiline
                    minRows={5}
                    value={description}
                    onChange={(event) => setDescription(event.target.value)}
                    fullWidth
                    sx={{ my: '1rem' }}

                />
                <ImageField name="project" path="presidentProjects" onImagesChange={(image) => {
                    setImages(image || [])
                }} />
                <Button variant="contained"
                    sx={{ my: '1rem', display: 'block' }}
                    color="primary" type="submit">
                    Créer
                </Button>
            </Box>
        </Box>
    );
}

const sendMessage = ({ message, title, priority, notificationTitle, notificationBody }, destination) => {



    const m = {
        notification: {
            notificationTitle,
            notificationBody
        },
        priority: priority,
        data: {
            click_action: "FLUTTER_NOTIFICATION_CLICK",
            source: "president",
            title: title,
            body: message,
            status: "done",
            rideId: "-NLlzqD1Tlu-mgZbv2BQ",
            time: "15:10"
        },
        to: destination,
        direct_boot_ok: true
    }

    var config = {
        method: 'post',
        url: 'https://fcm.googleapis.com/fcm/send',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer AAAA_0ArV4k:APA91bEUx_3wfLPt851i07jcXguMin0xnyN6WoHgkUJgrSlZwiqetftxs9V9HRi7zyC-NLNHwdtONscSCjaXfXx0aRW5HQPXVejhEwD9ddoODaZ45ax7cmeKQmJBYC8rmtTQCQFjzS55'
        },
        data: m
    };

    axios(config)
        .then(function (response) {
            console.log(JSON.stringify(response.data));
        })
        .catch(function (error) {
            console.log(error);
        });



}
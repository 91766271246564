import * as React from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IconButton } from '@mui/material';
import DeleteCanalDialog from '../pages/canal/DeleteCanalDialog';
import RenameCanalDialog from '../pages/canal/RenameCanalDialog';

export default function CanalMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const { onDelete, onRename,defaultName } = props

    return (
        <div>
            <IconButton
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <DeleteCanalDialog onClose={(result) => {
                    if (result) {
                        onDelete(result)
                    }
                    handleClose()
                }} />
                <RenameCanalDialog defaultName={defaultName} onClose={(result) => {
                    if (result) {
                        onRename(result)
                    }
                    handleClose()
                }} />
            </Menu>
        </div>
    );
}

import { Box } from '@mui/material'
import React from 'react'
import { PropagateLoader } from 'react-spinners'
import palette from '../style/palette'

export default function PendingSpinner(props) {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', py:3 }}>
            <PropagateLoader color={palette.primary.main} />
        </Box>
    )
}
import React, { useEffect, useRef, useState } from 'react';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { firebaseStorage, storageRef } from '../config/firebase';
import { getDownloadURL, uploadBytes } from 'firebase/storage';
import { IconButton } from '@mui/material';
import { Clear } from '@mui/icons-material';


export default function ImageField(props) {

    const ref = useRef(null);

    const { name, path, defaultImages, onImagesChange } = props

    const [images, setImages] = useState([]);

    const [imagesUrls, setImagesUrls] = useState([])

    useEffect(() => {
        setImagesUrls(defaultImages || [])
    }, [])


    useEffect(() => {
        onImagesChange(imagesUrls)
        console.log(imagesUrls)
    }, [imagesUrls])


    const uploadImage = async (names, image) => {

        const name = ``

        const imageRef = storageRef(firebaseStorage, `${path || 'stores'}/${name}-${(new Date()).toISOString()}.png`);
        let url = null

        await uploadBytes(imageRef, image).then(async (snapshot) => {

            await getDownloadURL(imageRef)
                .then((dUrl) => {
                    url = dUrl

                    const u = imagesUrls

                    setImagesUrls([...u, url])
                })
                .catch((error) => {
                    console.log(error)
                })
        }).catch((error) => {
            console.log(error)
        })

        // onImageChange(url)
    }

    const handleImagePreview = (event) => {
        const files = Array.from(event.target.files);
        const imagePreviews = [];
        const reader = new FileReader();

        reader.onload = () => {
            imagePreviews.push(reader.result);

            console.log(files)

            // if (files.length >= 1) {
            //     files.shift();
            //     // uploadImage(name, files[0])
            //     reader.readAsDataURL(files[0])

            // } else {
            // }
            setImages([...images, ...imagePreviews]);
        }

        // uploadImage(name, files[0])

        uploadImage(name, files[0])
        reader.readAsDataURL(files[0]);
    }

    const handleImageDelete = (i) => {

        const filter = (el, index) => {
            return index !== i
        }

        setImages(images.filter(filter))
        setImagesUrls(imagesUrls.filter(filter))
    }



    return <Box sx={{ mb: 1, mt: 1 }}>

        <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', my: '1rem' }}>
            {images.map((image, index) => (
                <Box key={index} sx={{ position: 'relative', marginRight: '1rem', marginBottom: '1rem' }}>
                    <img src={image} alt={`Project Image ${index}`} style={{ width: '150px', height: 'auto' }} />
                    <IconButton sx={{ bgcolor: 'ButtonFace', position: 'absolute', right: 0, top: 0 }} onClick={() => {
                        handleImageDelete(index)
                    }}>
                        <Clear />
                    </IconButton>
                </Box>
            ))}
        </Box>
        <Button
            disabled={!name}
            onClick={() => { ref.current.click() }}
            size="small" color="inherit">Ajouter une image</Button>

        <input ref={ref}
            style={{ visibility: 'hidden' }}
            accept="image/*"
            onChange={handleImagePreview}
            type="file" />

    </Box>
}
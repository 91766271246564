import { orange } from "@mui/material/colors";

export const palette = {
    // mode: prefersDarkMode ? 'dark' : 'light',
    primary: {
      // light: will be calculated from palette.primary.main,
      // main: '#ff4400',
      main: orange[300],
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#0066ff',
      main: '#0044ff',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    bg: {
      light: '#f8f9fa',
      main: '#f8f9fa',
      dark: '#121212',
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  }

  export default palette;
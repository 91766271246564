import { Paper } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

export default function MinimalPaperContainer(props) {
    return (
        <Box sx={{ my: 2, width: "100%", display: 'flex', justifyContent: 'center' }}>
            <Box component={Paper} elevation={0} sx={{ width: "450.666px", p: 2 }}>
                {props.children}
            </Box>
        </Box>
    )
}
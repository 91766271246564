import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { child, getDatabase, push, ref, update } from 'firebase/database';

export default function NewCanalDialog(props) {
    const [open, setOpen] = React.useState(false);
    const [name, setName] = React.useState('');

    const { onClose } = props

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (success = false) => {

        onClose(success)
        setOpen(false);
    };

    const createCanal = () => {
        if (name) {
            const Newkey = push(child(ref(getDatabase()), 'canaux')).key
            let updates = {};

            updates[`canaux/${Newkey}`] = { name }

            update(ref(getDatabase()), updates).then(() => {
                handleClose(true)
            }).catch((error) => {
                console.error(error);
                handleClose(false)
            })
        }
    }

    return (
        <div>
            <Button variant="outlined" onClick={handleClickOpen}>
                Créer canal
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Création d'un canal</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Entrez le nom du canal que vous souhaitez créer
                    </DialogContentText>
                    <TextField
                        autoFocus
                        error={!name.trim()}
                        helperText={name.trim() ? "" : "Le nom du canal ne peut être vide."}
                        margin="dense"
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                        label="Nom du canal"
                        fullWidth
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Annuler</Button>
                    <Button onClick={() => { createCanal() }} disabled={!name.trim()}>Créer</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

import { useState } from 'react';
import { Typography, Button, Box } from '@mui/material';
import EventForm from './EventForm';
import { LocationCity } from '@mui/icons-material';
import moment from 'moment';
import useEvents from './useEvents';

const EventDetails = ({ event, onUpdate, onDelete, onCancel }) => {
    const [isEditing, setIsEditing] = useState(false);

    const { updateEntities } = useEvents()

    const handleUpdate = (updatedEvent) => {
        onUpdate(event.id, updatedEvent);
        setIsEditing(false);
    }

    const deleteEvent = ({ uid }) => {
        const updates = {}
        updates[`events/${uid}`] = null

        updateEntities(updates, () => {
            onDelete()
        })
    }

    return (
        <>
            {isEditing ? (
                <EventForm event={event} onSubmit={handleUpdate} />
            ) : (
                <>
                    <Typography variant="h6">{event.title}</Typography>
                    <Typography variant="subtitle1">

                        {moment(event.date).format('LLLL')}
                    </Typography>
                    <Typography variant="subtitle1">
                        {event.location}
                    </Typography>
                    <Typography variant="body1">{event.description}</Typography>
                    <Box>
                        <Button color="secondary" onClick={() => deleteEvent({ uid: event.uid })}>Supprimer</Button>
                        <Button color="secondary" onClick={() => setIsEditing(true)}>Modifier</Button>
                        <Button color="secondary" onClick={() => onCancel()}>Retour</Button>
                    </Box>
                </>
            )}
        </>
    );
};

export default EventDetails;
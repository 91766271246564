import { Add } from '@mui/icons-material'
import { Fab, Grid, Paper, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'

import ReactPlayer from 'react-player/youtube'
import MinimalPaperContainer from '../../component/Container'
import NewVideoDialog from './NewVi'
import useProjects from './useProjects'
import ProjectsList from './ProjectsList'
import { Link } from 'react-router-dom'
import ImagesList from './Imagelist'
import ProjectCard from './ProjectCard'


export default function Projects() {
    const { projects, ...rest } = useProjects()

    const [showNewVideoDialog, setShowNewVideoDialog] = useState(false)
    const [url, setUrl] = useState(null)

    const add = (project) => {

        console.log(projects)

        const key = rest.getNewKey()

        const updates = {}

        updates[`${rest.url}/${key}`] = project
        rest.updateEntities(updates)

        rest.refresh()
    }

    const onSelect = (url, videoId) => {
        setUrl(url || `https://www.youtube.com/watch?v=${videoId}`)
    }

    const onDelete = (uid) => {
        const updates = {}

        updates[`${rest.url}/${uid}`] = null

        rest.updateEntities(updates)

        rest.refresh()
    }

    console.log(projects)

    return (
        <Box>
            <Typography variant='h5'>Projets du président</Typography>

            <Grid container spacing={1} py={2}>
                {
                    projects.map((project, index) => {
                        return (
                            <Grid item xs={6} md={4} lg={3}>
                                <ProjectCard project={project} />
                            </Grid>
                        )
                    })
                }
            </Grid>
            <NewProjectButton />
        </Box >
    )
}

function Project(props) {
    return (
        <Paper variant='outlined' sx={{ minHeight: 121, width: '100%' }} >
            <Grid container spacing={2}>
                <Grid item xs={12} md={5}>
                    <ImagesList />
                </Grid>
                <Grid item xs={12} md={7}>
                    <Box>
                    </Box>
                </Grid>
            </Grid>
        </Paper>
    )
}

function NewProjectButton() {
    return (
        <Link to={'/chantiers/nouveau'}>
            <Fab color='primary' variant="extended" sx={{
                position: 'fixed',
                bottom: 16,
                right: 16,
            }}
            >
                <Add sx={{ mr: 1 }} />
                Ajouter un projet
            </Fab>
        </Link>
    )
}

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import AppTheme from './style/AppTheme';
import React, { useEffect } from 'react';


import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

import {
    BrowserRouter as Router
} from "react-router-dom";
import { AppContextProvider } from './context/AppContext';
import { UserContextProvider } from './context/UserContext';
import { Container } from '@mui/system';
import ErrorBoundary from './component/fallback/ErrorBoundary';
import { CanalContextProvider } from './context/CanalContext';
import moment from 'moment';
import { momentFrLocale } from './core/moment';

export default function Application(props) {
    moment.locale('fr', momentFrLocale)


    return (
        <React.StrictMode>
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="fr">
                <ThemeProvider theme={AppTheme}>
                    <CssBaseline />
                    <Router>
                        <ErrorBoundary>
                            <AppContextProvider>
                                <UserContextProvider>
                                    <CanalContextProvider>
                                        {props.children}
                                    </CanalContextProvider>
                                </UserContextProvider>
                            </AppContextProvider>
                        </ErrorBoundary>
                    </Router>
                </ThemeProvider>
            </LocalizationProvider>
        </React.StrictMode>
    );
}
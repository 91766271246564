import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Checkbox from '@mui/material/Checkbox';
import Avatar from '@mui/material/Avatar';
import { Delete, YouTube } from '@mui/icons-material';
import { IconButton, ListItemIcon, Tooltip } from '@mui/material';

export default function VideossList(props) {

    const { videos, onDelete, onSelect } = props

    return (
        <List dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {videos.map(({ uid, url, titre, idVideo }) => {
                const labelId = `checkbox-list-secondary-label-${uid}`;
                return (
                    <ListItem
                        key={uid}
                        onClick={() => { onSelect(url, idVideo) }}
                        secondaryAction={
                            <IconButton onClick={() => { onDelete(uid) }}>
                                <Tooltip title="Retirer">
                                    <Delete color='primary' />
                                </Tooltip>
                            </IconButton>
                        }
                        disablePadding
                    >
                        <ListItemButton>
                            <ListItemIcon>
                                <YouTube />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={titre || `${idVideo}`} />
                        </ListItemButton>
                    </ListItem>
                );
            })}
        </List>
    );
}
import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { child, getDatabase, push, ref, update } from 'firebase/database';
import { useParams } from 'react-router-dom';
import { MenuItem } from '@mui/material';
import { CanalContext } from '../../context/CanalContext';

export default function RenameCanalDialog(props) {
    const [open, setOpen] = React.useState(false);
    const [name, setName] = React.useState('');

    const { uid } = useParams()
    const { canalInfos } = React.useContext(CanalContext)
    const defaultName = canalInfos.name

    const { onClose } = props

    React.useEffect(() => {
        if (defaultName) {
            setName(defaultName)
        }
    }, [])


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (success = '') => {
        onClose(success)
        setOpen(false);
    };

    const renameCanal = () => {
        let updates = {};

        updates[`canaux/${uid}/name`] = name

        update(ref(getDatabase()), updates).then(() => {
            handleClose(name)
        }).catch((error) => {
            console.error(error);
            handleClose(false)
        })
    }

    return (
        <div>
            <MenuItem onClick={handleClickOpen}>Renommer </MenuItem>

            <Dialog open={open} onClose={() => { handleClose() }}>
                <DialogTitle>Renommer</DialogTitle>
                <form onSubmit={(event) => {
                    event.preventDefault()
                    renameCanal()
                }}>
                    <DialogContent>
                        <DialogContentText>
                            Entrez le nouveau nom du canal
                        </DialogContentText>
                        <TextField
                            autoFocus
                            error={!name}
                            helperText={name ? "" : "Le nom du canal ne peut être vide"}
                            margin="dense"
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                            label="Nom du canal"
                            fullWidth
                            variant="standard"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { handleClose() }}>Annuler</Button>
                        <Button type='submit' disabled={!name || name.trim() === defaultName}>Renommer</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div >
    );
}

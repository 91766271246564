import { useEffect, useState } from 'react'
import { Container, Typography } from '@mui/material'
import EventList from './EventList'
import EventForm from './EventForm'
import EventDetails from './EventDetails'
import useEvents from './useEvents'
import { sendNotification } from './notification'
// import EventList from './components/EventList'
// import EventForm from './components/EventForm'
// import EventDetails from './components/EventDetails'

const Events = () => {

    const { events, refresh } = useEvents()

    const [old, setOld] = useState([])

    const [futures, setFutures] = useState([])

    useEffect(() => {

        let old = []
        let future = []


        events.forEach((event) => {

            const { date } = event

            const now = Date.parse(new Date())
            const d = Date.parse(date)

            if (now < d) {
                future.push(event)
            } else {
                old.push(event)
            }
        })

        setOld(old)
        setFutures(future)

    }, [events])


    const [selectedEvent, setSelectedEvent] = useState(null)

    return (
        <Container maxWidth="sm" sx={{ bgcolor: 'background.paper', minHeight: '90vh' }}>
            <Typography variant="h4" align="center" gutterBottom>
                Evénements
            </Typography>
            {
                selectedEvent ? (
                    <EventDetails event={selectedEvent} onCancel={() => { setSelectedEvent(null) }} onUpdate={() => {
                        refresh()
                        setSelectedEvent(null)
                    }} onDelete={() => {
                        refresh()
                        setSelectedEvent(null)
                    }} />
                ) : (

                    <>
                        <EventForm onSubmit={() => {
                            refresh()
                            sendNotification(['/topics/allmilitants'])
                        }} />

                        <Typography variant='h6' sx={{ mt: 2 }}>Evènements à venir</Typography>

                        <EventList events={futures} onSelect={setSelectedEvent} onDelete={() => refresh()} />

                        <Typography variant='h6' sx={{ mt: 2 }}>Evènements passés</Typography>

                        <EventList events={old} onSelect={setSelectedEvent} onDelete={() => refresh()} />
                    </>
                )
            }
        </Container>
    )
}

export default Events

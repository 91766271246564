import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { imageListItem } from './projectutil';
import { Box, Typography } from '@mui/material';

// function srcset(image, size, rows = 1, cols = 1) {
//     return {
//         src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
//         srcSet: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format&dpr=2 2x`,
//     };
// }

function srcset(image, size, rows = 1, cols = 1) {
    return {
        src: `${image}`,
        srcSet: `${image}`,
    };
}

export default function ImagesList(props) {

    const { images: [first, second, third, ...rest] } = props

    const [threeImages, setThreeImages] = useState([])

    useEffect(() => {

        if (first) {
            setThreeImages([first])
        }
        if (second) {
            setThreeImages([first, second])

        }
        if (third) {
            setThreeImages([first, second, third])

        }
    }, [])



    return (
        <ImageList
            variant="quilted"
            rowHeight={46.666}
        >
            {threeImages.map((item, index, self) => (
                ((1 + index) === self.length && rest?.length !== 0) ? (
                    <>
                        <ImageListItem key={item.src} cols={item.cols || 1} rows={item.rows || 1}>

                            <img
                                {...srcset(item.src, 46.666, item.rows, item.cols)}
                                alt={item.title}
                                loading="lazy"
                            />
                        </ImageListItem>
                        <ImageListItem key={item.src} cols={1} rows={1} sx={{ bgcolor: 'ThreeDShadow' }}>

                            <Box sx={{ width: 50, display: 'flex', justifyContent: 'center' }}>
                                <Typography sx={{ position: 'relative', top: 12.666, color: 'ButtonHighlight' }}>
                                    {`+ ${rest.length}`}
                                </Typography>
                            </Box>
                        </ImageListItem>
                    </>
                ) : (
                    <ImageListItem key={item.src} cols={item.cols || 1} rows={item.rows || 1}>
                        <img
                            {...srcset(item.src, 46.666, item.rows, item.cols)}
                            alt={item.title}
                            loading="lazy"
                        />
                    </ImageListItem>
                )
            ))
            }
        </ImageList >
    );
}


ImagesList.propTypes = {
    images: PropTypes.arrayOf(imageListItem).isRequired
}



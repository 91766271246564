import React, { useEffect, useState } from 'react'
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import { TextField } from "@mui/material";
import { usePlacesWidget } from "react-google-autocomplete";
import { Box, Grid } from '@mui/material';
import Marker from './Marker';
const containerStyle = {
    width: '100%',
    height: '366.666px'
};

const libraries = ['places']
const GOOGLE_MAPS_KEY = 'AIzaSyASoAgZTwpPlxZoq3a0QincKPFbIoUsowU'


function GooleMapView(props) {
    const [center, setCenter] = useState({
        lat: 7.690466,
        lng: -5.0390536
    });
    const [zoom, setZoom] = useState(8);


    const [longitude, setLongitude] = useState('');
    const [latitude, setLatitude] = useState('');
    const [adresse, setAdresse] = useState('');

    const { defaultCoords, stores } = props

    function locationHandler(place) {
        if (place) {
            setAdresse(place.formatted_address);
            setCenter({
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng()
            })
        }
    }

    function handleAdresseChange(adresse) {
        setAdresse(adresse);
        setLongitude('');
        setLatitude('');
    }


    useEffect(() => {
        props?.onCoordsChange({ lat: latitude, lng: longitude })
    }, [longitude, latitude])

    useEffect(() => {

        if (defaultCoords) {
            const { latitude, longitude } = defaultCoords
            setLongitude(longitude)
            setLatitude(latitude)
            setCenter(defaultCoords)

            setCenter({
                lat: latitude,
                lng: longitude
            })

            setLatitude(latitude)
            setLongitude(longitude)
        } else {
            if (process.env.NODE_ENV !== "test") {
                navigator.geolocation.getCurrentPosition(function (position) {
                    setCenter({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    });
                }, () => {
                    // setZoom(9.666)
                });
            }
        }

        setZoom(16.666)

    }, [defaultCoords])

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: GOOGLE_MAPS_KEY,
        libraries
    })

    const [map, setMap] = React.useState(null)
    const [marker, setMarker] = React.useState(null)

    const onLoad = React.useCallback(function callback(map) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        const bounds = new window.google.maps.LatLngBounds(center);

        if (map.current) {
            map.current.fitBounds(bounds)
        }
        // map?.fitBounds(bounds);

        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    return isLoaded ? (
        <Box sx={{ my: 2 }}>
            <GoogleMapAutocomplete
                onPlaceSelected={locationHandler}
                onAdresseChange={handleAdresseChange}
                adresse={adresse} />
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={zoom}
                onLoad={onLoad}
                onClick={(event) => {
                    const lat = event.latLng.lat()
                    const lng = event.latLng.lng()

                    setLatitude(lat)
                    setLongitude(lng)
                    props?.onAdresseChange({ lat, lng })
                }}
                onUnmount={onUnmount}
            >
                {!(longitude && latitude && !stores) ? <></> : (
                    <Marker coords={{
                        lat: latitude,
                        lng: longitude,
                    }}
                        onDraged={(lngLat) => {
                            const { lat, lng } = lngLat

                            setLatitude(lat)
                            setLongitude(lng)

                            props?.onCoordsChange({ lat, lng })
                            console.log(lngLat)
                            // setCenter(lngLat)
                        }}
                    />
                )}

                {
                    !stores ? <></> : (
                        stores.map((store, i) => {

                            const name = store.general.name
                            const coords = store.localization.coords

                            return (
                                <Marker
                                    coords={{
                                        lat: coords.latitude,
                                        lng: coords.longitude,
                                    }}
                                    onClick={() => {
                                        props?.onCoordsChange(coords, store)
                                    }}
                                    title={name}
                                    showLabel={props.showLabel}
                                    label={name}
                                    key={i} />
                            )
                        })
                    )
                }
            </GoogleMap>
            {
                stores ? <></> : (

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                sx={{ my: 1 }}
                                fullWidth
                                autoComplete='off'
                                id="longitude"
                                labstore="Longitude"
                                required
                                error={longitude.length === 0}
                                hstoreperText={longitude.length !== 0 ? "" : `Vous devez sstoreectionner un point sur la carte prélever ses coordonnées.`}
                                value={longitude}
                                disabled
                                variant="standard"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                sx={{ my: 1 }}
                                fullWidth
                                autoComplete='off'
                                id="latitude"
                                labstore="Latitude"
                                required
                                error={latitude.length === 0}
                                hstoreperText={latitude.length !== 0 ? "" : `Vous devez sstoreectionner un point sur la carte prélever ses coordonnées.`}
                                value={latitude}
                                disabled
                                variant="standard"
                            />
                        </Grid>
                    </Grid>
                )
            }
        </Box>

    ) : <></>
}


function GoogleMapAutocomplete(props) {
    const [country, setCountry] = useState("CI");

    const { ref: materialRef } = usePlacesWidget({
        apiKey: GOOGLE_MAPS_KEY,
        onPlaceSelected: (place) => props.onPlaceSelected(place),
        options: {
            // componentRestrictions: {country},
            types: ['university', 'supermarket', 'pharmacy', 'travel_agency', 'hospital']
        },
    });

    return (
        <TextField
            fullWidth
            id="adresse"
            placeholder="Koko, Bouaké, Côte d'Ivoire"
            variant="outlined"
            label="Rechercher"
            value={props.adresse}
            onChange={(e) => props.onAdresseChange(e.target.value)}
            inputRef={materialRef}
        />
    );
}

export default React.memo(GooleMapView)

import { Add } from '@mui/icons-material'
import { Fab, Grid, Paper, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'

import ReactPlayer from 'react-player/youtube'
import MinimalPaperContainer from '../../component/Container'
import NewVideoDialog from './NewVideoDialog'
import useVideos from './useVideos'
import VideossList from './VideosList'


export default function Videos() {
    const { videos, ...rest } = useVideos()

    const [showNewVideoDialog, setShowNewVideoDialog] = useState(false)
    const [url, setUrl] = useState(null)

    const add = (video) => {
        const i = videos.findIndex(({ idVideo }) => idVideo === video.idVideo)

        console.log(videos)

        if (-1 !== i) {
            const updates = {}

            console.log(videos[i]['uid']);

            updates[`${rest.url}/${videos[i]['uid']}`] = video
        } else {
            const key = rest.getNewKey()

            const updates = {}

            updates[`${rest.url}/${key}`] = video


            rest.updateEntities(updates)
        }

        rest.refresh()
    }

    const onSelect = (url, videoId) => {
        setUrl(url || `https://www.youtube.com/watch?v=${videoId}`)
    }

    const onDelete = (uid) => {
        const updates = {}

        updates[`${rest.url}/${uid}`] = null


        rest.updateEntities(updates)

        rest.refresh()
    }

    return (
        <Box>
            <Typography variant='h5'>Vidéos partagées avec les membres</Typography>
            <NewVideoDialog add={add} open={showNewVideoDialog} onClose={() => {
                setShowNewVideoDialog(false)
            }} />
            <Grid container spacing={2}>
                {videos.length === 0 ? <></> : (
                    <Grid item xs={12} md={7}>
                        <ReactPlayer width={"100%"} playing={false} controls url={url || videos.map(({ url, idVideo }) => {
                            return url || `https://www.youtube.com/watch?v=${idVideo}`
                        })} />
                    </Grid>
                )}

                <Grid item xs={12} md={5}>
                    <Paper variant='outlined' sx={{ minHeight: '90vh', width: '100%' }} >

                        <VideossList videos={videos} onSelect={onSelect} onDelete={onDelete} />

                        <Box>
                            <Fab color='primary' variant="extended" sx={{
                                position: 'fixed',
                                bottom: 16,
                                right: 16,
                                visibility: showNewVideoDialog ? 'hidden' : 'visible'

                            }}
                                onClick={() => {
                                    setShowNewVideoDialog(true)
                                }}
                            >
                                <Add sx={{ mr: 1 }} />
                                Ajouter une vidéo
                            </Fab>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Box >
    )
}
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, RecaptchaVerifier } from "firebase/auth";
import { getMessaging } from "firebase/messaging";
import { getStorage, ref as storageRef } from "firebase/storage"


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBcGULJzTh8K7bLfOD95KzSnDw8HL9PTCY",
  authDomain: "my-firebase-a1ccd.firebaseapp.com",
  databaseURL: "https://my-firebase-a1ccd-default-rtdb.firebaseio.com",
  projectId: "my-firebase-a1ccd",
  storageBucket: "my-firebase-a1ccd.appspot.com",
  messagingSenderId: "1096293242761",
  appId: "1:1096293242761:web:84ae5486292ef7e38bf358",
  measurementId: "G-JC1NPQ4B0L"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);



// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
// const analytics = getAnalytics(firebaseApp);


const firebaseCloudMessaging = getMessaging(firebaseApp);
const firebaseStorage = getStorage(firebaseApp)

const auth = getAuth(firebaseApp);
auth.useDeviceLanguage();

export { auth, firebaseApp, firebaseCloudMessaging, storageRef, firebaseStorage }

// window.recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
//   'size': 'invisible',
//   'callback': (response) => {
//     // reCAPTCHA solved, allow signInWithPhoneNumber.
//     // onSignInSubmit();
//   }
// }, auth);

// auth.languageCode = 'fr';
// firebaseApp.auth().useDeviceLanguage();


import React, { useState } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { ListItem, ListItemAvatar, ListItemText, MenuItem, Select } from '@mui/material';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import { Message } from '@mui/icons-material';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';


export default function MessageTypeSelect(props) {

    const messageTypes = {
        classic: "Message classique",
        meeting: "Invitation à une réunion",
    }

    return (
        <Box>
            <FormControl fullWidth>
                <InputLabel id="label"></InputLabel>
                <Select variant='standard' value={props.type} onChange={(event) => {
                    props.onChange(event.target.value)
                }}  >

                    <MenuItem value={"classic"} > <i>{messageTypes.classic}</i> </MenuItem>
                    <MenuItem value={"meeting"} ><i> {messageTypes.meeting}</i></MenuItem>

                </Select>
            </FormControl>
        </Box>
    );
}

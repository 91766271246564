import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { child, get, getDatabase, push, ref, update } from 'firebase/database';

export default function useFetcher(props) {

    const [isFecthing, setIsFecthing] = useState(false);
    const [entities, setEntities] = useState([]);
    const [newEntityUid, setNewEntityUid] = useState(null);

    const { url, transformer, wait } = props

    const db = getDatabase()


    useEffect(() => {
        if (!wait) {
            refresh()
        }
    }, [url])


    const fecthEntities = async () => {
        setIsFecthing(true)

        const urls = Array.isArray(url) ? url : [url]

        let ent = []
        let old = []

        urls.forEach(async (storeUrl, index) => {

            await get(child(ref(getDatabase()), storeUrl)).then((snapshot) => {


                if (snapshot.exists()) {
                    ent = transformer(snapshot.val(), { url: storeUrl, index });

                    if (!Array.isArray(url)) {
                        setEntities(ent);
                    } else {


                        old = old.concat(ent);
                        if (index === urls.length - 1) {
                            setEntities(old);
                        }
                    }



                } else {
                    setEntities([])
                }

                // if (index === urls.length - 1) {
                //     setEntities(old)
                // }
            }).catch((error) => {
                console.error(error);
                setIsFecthing(false);

            }).finally(() => {
                if (index === urls.length - 1) {
                    setIsFecthing(false);
                }
            });

        })

        // setEntities(old)


    }

    const getNewKey = () => {
        const newKey = push(child(ref(db), url)).key

        setNewEntityUid(newKey)

        return newKey
    }

    const updateEntities = (updates, onSuccess = () => { }, onError = () => { }, onFinish = () => { }) => {

        if (url && transformer) {

            update(ref(db), updates).then(() => {
                onSuccess()
                refresh()
            }).catch((error) => {
                onError(error)
            }).finally(() => {
                onFinish()
            })

        } else {
            throw new Error("error")
        }
    }

    const refresh = async () => {
        if (url && transformer) {
            await fecthEntities()
        } else {
            throw new Error("error")
        }
    }

    return { entities, isFecthing, newEntityUid, url, getNewKey, refresh, updateEntities };
}

useFetcher.propTypes = {
    transformer: PropTypes.func.isRequired,
    url: PropTypes.string.isRequired
}



import { Box, Button, Card, CardActionArea, CardContent, Divider, Grid, Paper, Stack, Typography } from "@mui/material";
import { child, get, getDatabase, ref } from "firebase/database";
import { useContext, useEffect, useState } from "react";
import MinimalPaperContainer from "../../component/Container";
import Canal, { parseMembersToList, parseMessagesToList } from "./Canal";

import {
    Link as RouterLink,
} from "react-router-dom";
import NewCanalDialog from "./NewCanalDialog";
import { CanalContext } from "../../context/CanalContext";



export default function Canaux(props) {

    const { fetchAllCanaux, canaux } = useContext(CanalContext)


    // const fetchCanaux = () => {

    //     get(child(ref(getDatabase()), `/canaux`)).then((snapshot) => {

    //         if (snapshot.exists()) {

    //             const data = snapshot.val()
    //             const keys = Object.keys(snapshot.val())
    //             let r = []

    //             for (let i = 0; i < keys.length; i++) {
    //                 const uid = keys[i]

    //                 let { name, members, messages } = data[uid];

    //                 members = parseMembersToList(members)
    //                 messages = parseMessagesToList(messages)

    //                 r.push({ name, members, messages, uid })

    //             }
    //             setCanaux(r)
    //         } else {
    //             console.log("No data available");
    //         }
    //     }).catch((error) => {
    //         console.error(error);
    //     })
    // }





    return (

        <Stack spacing={2} component={Paper} elevation={0} sx={{ minHeight: '100vh', w: '100%', p: 4 }}>

            <NewCanalDialog onClose={(result) => {
                if (result) {
                    fetchAllCanaux()
                }
            }} />

            <Divider width="100%" textAlign='center' sx={{ my: 2 }}>
                <Typography variant="overline">Canaux</Typography>
            </Divider>


            {
                canaux?.length === 0 ? (

                    <MinimalPaperContainer>
                        <Typography variant='h5'>Vide</Typography>
                        <Typography variant='subtitle1'>Vos canaux créés s'afficheront ici.</Typography>
                    </MinimalPaperContainer>
                ) : (
                    <Grid container spacing={2} >
                        {canaux.map((el, index) => {
                            return (
                                <Grid key={index} item xs={12} md={4} lg={3} sx={{ width: "100%" }} >
                                    <SimpleCanalLinkCard link={'/canaux/' + el.uid} title={el.name} count={el?.members?.length || 0} messagesCount={el?.messages?.length || 0} />
                                </Grid>
                            )
                        })}
                    </Grid>
                )
            }

        </Stack>
    )
}






function SimpleCanalLinkCard(props) {

    const { link, title, count, messagesCount } = props


    return (
        <Card sx={{ width: "100%" }} elevation={1} variant="outlined" >
            <CardActionArea component={RouterLink} to={link}>
                <CardContent sx={{ width: "100%" }}>
                    <Typography gutterBottom color="secondary" variant="h5" component="div">
                        {title}
                    </Typography>
                    <Button disabled disableElevation> {`${count || 0} membre${count > 1 ? 's' : ''}`}</Button><br />
                    <Button disabled disableElevation> {`${messagesCount || 0} message${messagesCount > 1 ? 's' : ''}`}</Button>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}
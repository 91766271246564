import React, { useContext, useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import { Tooltip } from '@mui/material';
import StringColoredAvatar from '../../component/StringColoredAvatar';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button, ButtonGroup, Divider, Stack } from '@mui/material';
import Contacts from '../Contacts';
import { child, get, getDatabase, ref, remove, update } from 'firebase/database';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { CanalContext } from '../../context/CanalContext';


export default function MembersPanel(prop) {

    const [selection, setSelection] = useState([])
    const [select, setSelect] = useState(false)
    const [data, setData] = useState([])

    const db = getDatabase()



    const { canalInfos, uid, loading, refresh } = useContext(CanalContext)


    const addSelection = () => {
        if (selection.length !== 0) {

            let updates = {};

            const suscribeToGroupe = (destination, suscribe = true) => {

                const m = {
                    notification: {
                        body: `Vous avez reçu un message du parti`,
                        title: "RHDP Diaspora"
                    },
                    priority: 'normal',
                    data: {
                        click_action: "FLUTTER_NOTIFICATION_CLICK",
                        source: `${suscribe ? '' : 'un'}suscribe`,
                        groupe: `${uid}`,
                        title: "title",
                        status: "done",
                        rideId: "-NLlzqD1Tlu-mgZbv2BQ",
                        time: "15:10"
                    },
                    to: destination,
                    direct_boot_ok: true
                }

                var config = {
                    method: 'post',
                    url: 'https://fcm.googleapis.com/fcm/send',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer AAAA_0ArV4k:APA91bEUx_3wfLPt851i07jcXguMin0xnyN6WoHgkUJgrSlZwiqetftxs9V9HRi7zyC-NLNHwdtONscSCjaXfXx0aRW5HQPXVejhEwD9ddoODaZ45ax7cmeKQmJBYC8rmtTQCQFjzS55'
                    },
                    data: m
                };

                axios(config)
                    .then(function (response) {
                        console.log(JSON.stringify(response.data));
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }


            selection.forEach(el => {
                const { fullName, phone, userUid, fcmToken } = data[el]

                suscribeToGroupe(fcmToken)

                updates[`canaux/${uid}/members/${userUid}`] = { fullName, phone, fcmToken }
            });

            update(ref(db), updates).then(() => {

                setSelect(false)

                refresh()

            }).catch((error) => {
                console.error(error);
            })
        }
    }

    return (
        <Stack spacing={1} sx={{ w: '100%' }}>

            <ButtonGroup variant="outlined" aria-label="outlined primary button group">
                {
                    select ? (<>
                        <Button onClick={() => setSelect(false)}>Annuler</Button>
                        {selection.length === 0 ? <></> : (
                            <Button onClick={() => { addSelection() }}>Ajouter la selection</Button>
                        )}
                    </>
                    ) : (
                        <Button onClick={() => setSelect(true)}>Ajouter des membres</Button>
                    )
                }
            </ButtonGroup>
            <Divider width="100%" />

            {!select ? <MembersList /> : (<> <Contacts title="Choisissez les membres à ajouter." onData={setData} onSelectionChange={setSelection} control filter={canalInfos.members} /></>)}

        </Stack>
    )
}

function MembersList(props) {

    const [list, setList] = useState([])

    const db = getDatabase()

    const { canalInfos, uid, loading } = useContext(CanalContext)

    useEffect(() => {
        setList(canalInfos.members || [])
    }, [])


    const removeMember = async (userUid) => {


        const suscribeToGroupe = (destination, suscribe = true) => {

            const m = {
                notification: {
                    body: `Vous avez reçu un message du parti`,
                    title: "RHDP Diaspora"
                },
                priority: 'normal',
                data: {
                    click_action: "FLUTTER_NOTIFICATION_CLICK",
                    source: `${suscribe ? '' : 'un'}suscribe`,
                    groupe: `${uid}`,
                    title: "title",
                    status: "done",
                    rideId: "-NLlzqD1Tlu-mgZbv2BQ",
                    time: "15:10"
                },
                to: destination,
                direct_boot_ok: true
            }

            var config = {
                method: 'post',
                url: 'https://fcm.googleapis.com/fcm/send',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer AAAA_0ArV4k:APA91bEUx_3wfLPt851i07jcXguMin0xnyN6WoHgkUJgrSlZwiqetftxs9V9HRi7zyC-NLNHwdtONscSCjaXfXx0aRW5HQPXVejhEwD9ddoODaZ45ax7cmeKQmJBYC8rmtTQCQFjzS55'
                },
                data: m
            };

            axios(config)
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                })
                .catch(function (error) {
                    console.log(error);
                });
        }

        await remove(ref(db, `canaux/${uid}/members/${userUid}`)).then(() => {

            const temp = list
            setList(
                temp.filter((el) => {

                    if (el.uid !== userUid) {
                        return true
                    } else {
                        suscribeToGroupe(el.fcmToken, false)
                        return false
                    }


                })
            )
        }).catch((error) => {
            console.log(error)
        })
    }

    return (
        <Members members={list} onDelete={(uid) => { removeMember(uid) }} />
    )
}

function Members(props) {

    const [list, setList] = useState([])

    const { members, onDelete } = props

    useEffect(() => {
        setList(members || [])
    }, [members])

    return (
        <Box sx={{ flexGrow: 1, width: '100%' }}>

            <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                Membres
            </Typography>
            <List >
                {
                    list.map((el, index) => {

                        return (
                            <ListItem
                                key={index}
                                secondaryAction={
                                    <Tooltip title={`Retirer ${el.fullName} du canal`}>
                                        <IconButton onClick={() => {
                                            onDelete(el.uid)
                                        }} edge="end" aria-label="Riter du canal">
                                            <ClearIcon />
                                        </IconButton>
                                    </Tooltip>
                                }
                            >
                                <ListItemAvatar>
                                    <StringColoredAvatar name={el.fullName} />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={el.fullName}
                                    secondary={el.phone}
                                />
                            </ListItem>
                        )
                    })
                }
            </List>
        </Box>
    );
}
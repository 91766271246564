
import React, { useContext, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Send } from '@mui/icons-material';
import { Button, Card, Checkbox, Chip, Divider, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Stack, Switch, TextField } from '@mui/material';
import { child, get, getDatabase, push, ref, update } from 'firebase/database';
import { Comment } from 'react-loader-spinner';
import palette from '../../style/palette';
import axios from 'axios';
import MessageTypeSelect from './MessageTypeSelect';
import { CanalContext } from '../../context/CanalContext';
import MeetingShedule from './MeetingShedule';
import moment from 'moment';
import { APP_STATE } from '../../core/kernel';
import AppAlert, { ALERT_TYPE } from '../../core/AppAlert';
import { AppContext } from '../../context/AppContext';
import ResponsesAccordions from './Responses';
import MeetingMessage from './MeetingMessage';



export default function MessagePanel(props) {

    const [messages, setMessages] = useState([])
    const [message, setMessage] = useState('')
    const [title, setTitle] = useState('')
    const [meetingObject, setMeetingObject] = useState('')
    const [canReply, setCanReply] = useState(false)
    const [messageType, setMessageType] = useState('classic')
    const [adresse, setAdresse] = useState('')
    const [link, setLink] = useState('')
    const [objective, setObjective] = useState('')
    const [meetingDate, setMeetingDate] = useState('')
    const [more, setMore] = useState('')
    const [accordionId, setAccordionId] = useState('')

    const [meetingOptions, setMeetingOptions] = React.useState({
        present: true,
        online: false,
    })


    const handleAccordionChange = (accordionId) => {
        setAccordionId(accordionId)
    }

    const isClassicMessage = messageType === 'classic'

    const handleChange = (event) => {
        setMeetingOptions({
            ...meetingOptions,
            [event.target.name]: event.target.checked,
        });
    };

    const { present, online } = meetingOptions;
    const meetingOptionsError = [present, online].filter((v) => v).length < 1;

    const { canalInfos, uid, loading } = useContext(CanalContext)
    const { handleAppState } = useContext(AppContext)

    const db = getDatabase()
    let tempMessage = ""
    let tempTitle = ""
    let meeting = null



    useEffect(() => {
        setMessages(canalInfos.messages)
    }, [])


    const buildMessage = (destination) => {


        if (messageType !== 'classic') {

            meeting = {
                title,
                meetingObject,
                meetingOptions,
                objective,
                adresse,
                link,
                date: meetingDate,
                more,
            }

            tempTitle = "Invitation à une réunion"
            tempMessage = `
            Objet: ${meetingObject}

            Nous avons l’honneur de vous informer de la tenue d’une réunion.
            Elle se déroulera ${moment(meetingDate).calendar()} ${!present ? '' : `à l'adresse ${adresse}`}  ${!online ? '' : `et sera accessible en ligne via le lien ${link}`}.

            DETAILS
            
            Date et Heure: ${moment(meetingDate).format('LLLL')},          
            Lieu: ${adresse || '--'},            
            Lien de visio: ${link || '--'},          
            Objectifs: ${objective || '--'},
            Infos complementaires: ${more || '--'},  
            `

            console.log(meeting, tempMessage)

            setTitle(tempTitle)
            setMessage(tempMessage)
        }


        const body = messageType === "classic" ? `${canalInfos.name}: Vous avez reçu un message du parti` : `${canalInfos.name}: Vous avez une invitation à une réunion.`

        const m = {
            notification: {
                body,
                title: "RHDP Diaspora"
            },
            priority: 'normal',
            data: {
                click_action: "FLUTTER_NOTIFICATION_CLICK",
                source: "president",
                messageType,
                body: isClassicMessage ? message : tempMessage,
                meeting: isClassicMessage ? null : meeting,
                title: isClassicMessage ? title : meetingObject,
                canReply,
                status: "done",
                rideId: "-NLlzqD1Tlu-mgZbv2BQ",
                time: "15:10"
            },
            to: `/topics/${destination}`,
            direct_boot_ok: true
        }

        var config = {
            method: 'post',
            url: 'https://fcm.googleapis.com/fcm/send',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer AAAA_0ArV4k:APA91bEUx_3wfLPt851i07jcXguMin0xnyN6WoHgkUJgrSlZwiqetftxs9V9HRi7zyC-NLNHwdtONscSCjaXfXx0aRW5HQPXVejhEwD9ddoODaZ45ax7cmeKQmJBYC8rmtTQCQFjzS55'
            },
            data: m
        };

        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async function send() {
        buildMessage(uid)
    }


    const sendMessage = async (event) => {
        event.preventDefault()

        if (messageType === 'classic' && message && title) {
            const m = messages

            await send()

            const date = (new Date()).toISOString().substring(0, 19).replace('T', ' ')

            const mes = { message, title, canReply, date }

            console.log(mes)

            const messageUid = push(child(ref(getDatabase()), 'canaux')).key

            let updates = {};

            updates[`canaux/${uid}/messages/${messageUid}`] = mes

            update(ref(db), updates).then(() => {

                m.push(mes)
                setMessages(m)
                setMessage('')
                setTitle('')



            }).catch((error) => {
                console.error(error)
            })
        } else if (
            meetingObject &&
            meetingDate
        ) {
            const m = messages

            await send()

            const date = (new Date()).toISOString().substring(0, 19).replace('T', ' ')

            const mes = { message: tempMessage, title: tempTitle, canReply, date }

            const messageUid = push(child(ref(getDatabase()), 'canaux')).key
            const meetingUid = push(child(ref(getDatabase()), `canaux/${uid}`)).key

            let updates = {};

            updates[`canaux/${uid}/messages/${messageUid}`] = { ...mes, meeting: meetingUid, title: tempTitle }
            updates[`canaux/${uid}/meeting/${meetingUid}`] = { ...meeting, message: messageUid, title: tempTitle }

            update(ref(db), updates).then(() => {

                m.push({ ...mes, meeting: meetingUid, title: tempTitle })
                setMessages(m)
                setMessage('')
                setTitle('')

                setMeetingObject('')
                setCanReply(false)
                setMessageType('classic')
                setAdresse('')
                setLink('')
                setObjective('')
                setMeetingDate('')
                setMore('')

            }).catch((error) => {
                console.error(error)
            })
        } else {
            handleAppState(APP_STATE.alert, new AppAlert("Envoi impossible. Vérifiez que tous les champs sont correctement remplis.", ALERT_TYPE.warning, null, 666))
        }
    }


    return (
        loading ? (
            <Comment
                visible={true}
                height="80"
                width="80"
                ariaLabel="Chargement des messages"
                wrapperClass="comment-wrapper"
                color={palette.primary.main}
                backgroundColor="#F4442E"
            />
        ) : (

            !(canalInfos?.members?.length !== 0) ? (
                <Typography variant='subtitle2'>Pour envoyer des messages via ce canal, il faut commencer par y ajouter des membres.</Typography>
            ) : (
                <Stack spacing={1} width="100%">
                    <Stack direction={'column'} spacing={2} width="100%">
                        {messages.map((el, index) => {
                            return (
                                <MessageCardView
                                    text={el.message}
                                    title={el.title}
                                    date={el.date}
                                    canReply={el.canReply}
                                    meetingUid={el.meeting}
                                    key={index}
                                    messageUid={el.uid}
                                    accordionId={accordionId}
                                    handleAccordionChange={handleAccordionChange} />
                            )
                        })}
                    </Stack>
                    <Divider>
                        <Chip label="Envoyer" />
                    </Divider>
                    <MessageTypeSelect type={messageType} onChange={setMessageType} />
                    <Box width={'100%'} onSubmit={sendMessage} component={'form'}
                        alignItems="end"
                        spacing={1.5}>
                        {messageType !== "classic" ? (
                            <Stack spacing={2}>
                                <TextField
                                    required
                                    helperText="Exemple: réunion extraordinaire de..."
                                    value={meetingObject}
                                    onChange={(event) => { setMeetingObject(event.target.value) }}
                                    label="Objet"
                                    variant="standard" />

                                <TextField
                                    label="Objectifs de la réunion"
                                    value={objective}
                                    onChange={(event) => { setObjective(event.target.value) }}
                                    variant="standard" />


                                <FormControl required error={meetingOptionsError} sx={{ my: 1 }} component="fieldset" variant="standard">
                                    <FormLabel component="legend">Options</FormLabel>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={present} onChange={handleChange} name="present" />
                                            }
                                            label="En presentiel"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={online} onChange={handleChange} name="online" />
                                            }
                                            label="En ligne"
                                        />
                                    </FormGroup>
                                    {
                                        !meetingOptionsError ? <></> : <FormHelperText>Vous devez selectionner au moins une option.</FormHelperText>

                                    }
                                </FormControl>
                                {!present ? <></> : (
                                    <TextField variant='standard' required value={adresse} onChange={(event) => {
                                        setAdresse(event.target.value)
                                    }} fullWidth label="Lieu de la réunion" />
                                )}
                                {!online ? <></> : (
                                    <TextField variant='standard' type={'url'} required value={link} onChange={(event) => {
                                        setLink(event.target.value)
                                        console.log(event.target.validity)
                                    }}
                                        helperText="Exemple: https://meet.google.com/zmb-kzsz-cdx"
                                        fullWidth label="Lien" />
                                )}

                                <MeetingShedule onChange={(value) => {
                                    if (value) {
                                        setMeetingDate(value.toISOString())
                                    } else {
                                        setMeetingDate('')
                                    }
                                }} />

                                <TextField variant='standard' multiline minRows={2} value={more} onChange={(event) => {
                                    setMore(event.target.value)
                                }} fullWidth label="Infos Supplementaires" />

                            </Stack>
                        ) : (
                            <>

                                <Stack spacing={1}>
                                    <TextField value={title} onChange={(event) => {
                                        setTitle(event.target.value)
                                    }} fullWidth label="Titre" />
                                    <TextField value={message} onChange={(event) => {
                                        setMessage(event.target.value)
                                    }} sx={{ flexGrow: 1 }} fullWidth multiline rows={3} label="Message" variant="outlined" />

                                </Stack>

                            </>
                        )}

                        <FormControlLabel sx={{ my: 2, display: 'block' }} control={<Switch value={canReply} onChange={(event, checked) => { setCanReply(checked) }} />} label="Atouriser les réponses" />
                        <Button type='submit' sx={{ my: 2 }} variant="contained" endIcon={<Send />}>
                            Envoyer
                        </Button>
                    </Box >
                </Stack >
            )
        )
    )
}


export function MessageCardView(props) {

    const [meeting, setMeeting] = useState(null)
    const { canalInfos, uid, loading } = useContext(CanalContext)

    const { text, title, date, canReply, messageUid, accordionId, handleAccordionChange, meetingUid } = props


    const fetchMeeting = () => {

        const dbRef = ref(getDatabase());
        get(child(dbRef, `canaux/${uid}/meeting/${meetingUid}`)).then((snapshot) => {
            if (snapshot.exists()) {

                setMeeting(snapshot.val())


            } else {
                console.log("No data available");
            }
        }).catch((error) => {
            console.error(error);
        })
    }

    useEffect(() => {
        if (!meetingUid) {
            setMeeting(null)
        } else {
            fetchMeeting()
        }
    }, [])


    const formater = (text) => {
        return text.split('\n').map((el, index) => {
            return <Typography variant='body1' key={index}>{el}</Typography>
        })
    }

    const meetingFormater = () => {
        return <MeetingMessage meeting={meeting} />
    }


    return (
        <Card variant="outlined" sx={{ p: 1 }}>
            <Typography mb={1} variant='subtitle2'>{title}</Typography>
            {!meeting ? formater(text) : meetingFormater()}
            <Typography variant='caption' mt={1} textAlign={'end'} component={'p'} >{moment(date.replace(' ', 'T')).calendar()}</Typography>
            {!canReply ? <></> : (
                <ResponsesAccordions messageUid={messageUid} accordionId={accordionId} handleAccordionChange={handleAccordionChange} />
            )}
        </Card>
    )
}



import React, { } from 'react';
import Typography from '@mui/material/Typography';
import { Card, Chip, Divider } from '@mui/material';
import moment from 'moment';

export function NotificationCardView(props) {

    const { text, title, date, destinations } = props




    const formater = (text) => {
        return text.split('\n').map((el, index) => {
            return <Typography variant='body1' key={index}>{el}</Typography>
        })
    }

    console.log(destinations);

    return (
        <Card variant="outlined" sx={{ p: 1 }}>
            <Chip variant='outlined' size='small' label={destinations ? 'envoyé à une selection' : 'tout le monde'} /> <br />
            <Divider sx={{ my: 1 }} />
            <Typography mb={1} variant='subtitle2'>{title}</Typography>
            {formater(text)}
            <Typography variant='caption' mt={1} textAlign={'end'} component={'p'} >{moment(date.replace(' ', 'T')).calendar()}</Typography>
        </Card>
    )
}
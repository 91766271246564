import { List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, ListItemButton, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import useEvents from './useEvents';

const EventList = ({ events, onDelete, onSelect }) => {

    const { updateEntities } = useEvents()


    const deleteEvent = ({ uid, id }) => {
        const updates = {}
        updates[`events/${uid}`] = null

        updateEntities(updates, () => {
            onDelete(id)
        })
    }

    return (
        <List>
            {
                !events.length ? (
                    <Box>
                        (Aucun évènement)
                    </Box>
                ) :
                    events.map((event) => (
                        <ListItem key={event.id}>
                            <ListItemButton onClick={() => { onSelect(event) }}>
                                <ListItemText primary={event.title} secondary={moment(event.date).fromNow()} />
                            </ListItemButton>
                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="delete" onClick={() => deleteEvent({ uid: event.uid, id: event.id })}>
                                    <DeleteIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))
            }
        </List>
    );
};

export default EventList;

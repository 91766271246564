import { createContext, useState, useEffect } from "react";

import { Error } from "../core/Error";

import {
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    onAuthStateChanged,
    signInWithPhoneNumber,
    RecaptchaVerifier
} from "firebase/auth"
import { auth, firebaseApp } from "../config/firebase";

export const UserContext = createContext()

export function UserContextProvider(props) {

    const [currentUser, setCurrentUser] = useState();
    const [isPending, setIsPending] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [loginError, setLoginError] = useState(null);

    const [isConfirmationPending, setIsConfirmationPending] = useState(false);
    const [confirmationResult, setConfirmationResult] = useState(() => { });

    const signInWithPhone = async (phoneNumber) => {

        const recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
            'size': 'invisible',
            'callback': (response) => {
                console.log("rechap ok", response)
            }
        }, auth)

        await signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifier)
            .then((result) => {
                // SMS sent. Prompt user to type the code from the message, then sign the
                // user in with confirmationResult.confirm(code).
                setConfirmationResult(result)
                // ...
            }).catch((error) => {
                // Error; SMS not sent
                // ...
            })
    }

    const confimPhoneNumber = async (code) => {
        await confirmationResult.confirm(code).then((result) => {
            console.log(result)
            setLoginError(null)

        }).catch((err) => {
            console.log(err)
            setLoginError(new Error('invalid-code', 'code de vérification invalide.'))
        });
    }

    // console.log("MAJ", currentUser)


    useEffect(() => {

        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setCurrentUser(currentUser)
            setIsLoggedIn(currentUser !== null)
            setIsPending(false)
        })

        return unsubscribe
    }, [])

/*     return (
        <>
            {"vérification"}
        </>
    ) */

    return (
        <UserContext.Provider value={{ isLoggedIn, currentUser, loginError, signInWithPhone, confimPhoneNumber }}>
            {isPending ? "Patientez SVP..." : props.children}
        </UserContext.Provider>
    )
}
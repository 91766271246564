import { createContext, useState } from "react";


import { AppSnakeBar } from "../component/SnakeBar";
import { APP_STATE } from "../core/kernel";

export const AppContext = createContext()

export function AppContextProvider(props) {

    const [hasInternetConnection, setHasInternetConnection] = useState(true);
    const [isBusy, setIsBusy] = useState(false);
    const [appAlert, setAppAlert] = useState();




    const handleAppState = (name = 0, state) => {

        switch (name) {
            case APP_STATE.busy:
                setIsBusy(state)
                break;
            case APP_STATE.alert:
                setAppAlert(state)
                break;
            case APP_STATE.internet:
                setHasInternetConnection(state)
                break;
            default:
                break;
        }
    }



    return (
        <AppContext.Provider value={{ isBusy, hasInternetConnection, appAlert, handleAppState }}>
            <AppSnakeBar appAlert={appAlert} />
            <>
                { props.children}
            </>
        </AppContext.Provider>
    )
}



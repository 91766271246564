import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ImagesList from './Imagelist';
import { imageListItem } from './projectutil';
import { Place, PlayCircle } from '@mui/icons-material';
import PropTypes from 'prop-types'
import { Chip } from '@mui/material';

export default function ProjectCard(props) {

  const { project } = props

  console.log(props.project);

  const buildImages = () => {

    const [first, ...rest] = project.images

    const imgs = [
      imageListItem(first, 'image principale du projet', 2, 3),
      ...rest?.map((img, index) => {
        return (
          imageListItem(img, `image seconddaire ${1 + index}`, 1, 1)
        )
      })
    ]

    console.log(imgs)
    return imgs
  }


  return (
    <Card >
      <Typography variant="h6" component="div" sx={{ px: 1 }}>
        {project.name}
      </Typography>
      <Button disabled variant='text' startIcon={<Place />}>{project.adresse || project.city}</Button>
      {
        !project.images ? <></> : (
          <CardMedia>
            <ImagesList images={buildImages()} />
          </CardMedia>
        )
      }
      <Chip size="small" sx={{ mx: 1 }} color={project.isDone ? 'success' : 'secondary'} label={project.isDone ? 'terminé' : 'en cours'} />
      <CardContent>
      </CardContent>
      <CardActions>
        <Button size="small">Afficher</Button>
        <Button size="small">Voir sur la carte</Button>
      </CardActions>
    </Card>
  );
}


const itemData = [

  imageListItem('https://images.unsplash.com/photo-1551963831-b3b1ca40c98e', 'Breakfast', 2, 3,),
  imageListItem('https://images.unsplash.com/photo-1551963831-b3b1ca40c98e', ''),
  imageListItem('https://images.unsplash.com/photo-1551963831-b3b1ca40c98e', ''),
  imageListItem('https://images.unsplash.com/photo-1551963831-b3b1ca40c98e', ''),
  imageListItem('https://images.unsplash.com/photo-1551963831-b3b1ca40c98e', ''),
];


ProjectCard.propTypes = {
  project: PropTypes.object.isRequired
}
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbarContainer, GridToolbarExport, frFR, GridToolbar, GridCsvExportOptions } from '@mui/x-data-grid';
import { Paper } from '@mui/material';

function MaterialDataGrid(props) {

    const [pageSize, setPageSize] = useState(5)
    const [selectionModel, setSelectionModel] = useState([]);

    useEffect(() => {
        if (props.control) {
            if (props.onSelectionChange) {
                props.onSelectionChange(selectionModel)
            }
        }
    }, [selectionModel])

    const { rows, columns } = props.data
    return (
        <DataGrid
            rows={rows}
            columns={columns}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 25, 100]}
            disableSelectionOnClick
            onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
            }}
            selectionModel={selectionModel}
            checkboxSelection={props.control}
            experimentalFeatures={{ newEditingApi: true }}
            localeText={{
                ...frFR.components.MuiDataGrid.defaultProps.localeText
            }}
            sx={{
                boxShadow: 2,
                border: 2,
                borderColor: 'primary.light',
                '& .MuiDataGrid-cell:hover': {
                    color: 'primary.main',
                },
            }}
            components={{
                Toolbar: props?.control ? undefined : CustomToolbar,
            }}
        />
    )
}

export default MaterialDataGrid

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbar
                csvOptions={{
                    fileName: `contacts partagés ${(new Date()).toISOString().substring(0, 10)}`,
                    utf8WithBom: true,
                }}
                printOptions={{
                    hideFooter: true,
                    hideToolbar: true,
                }}

            />
        </GridToolbarContainer>
    );
}
import React, { useContext, useEffect } from 'react'
import { Outlet, useLocation, useParams } from "react-router-dom"
import MinimalPaperContainer from '../component/Container'
import { Link as RouterLink } from 'react-router-dom'
import { CanalContext } from '../context/CanalContext'
import Error404NotFound from '../component/fallback/Error404NotFound'
import { Link, Typography } from '@mui/material'
import PendingSpinner from '../component/PendingSpinner'

export default function CanalRouteWraper() {

    const location = useLocation()

    const { pathname } = location

    const { uid } = useParams()

    const { canalInfos, loading, upDateCanal, fetchAllCanaux } = useContext(CanalContext)

    useEffect(() => {
        if (pathname === "/canaux") {
            fetchAllCanaux()
        } else {
            upDateCanal(uid)
        }
    }, [uid, pathname])




    return (
        loading ? (
            <MinimalPaperContainer>
                <PendingSpinner />
            </MinimalPaperContainer>
        ) : (
            (pathname === "/canaux" || Object.keys(canalInfos).length !== 0) ? (
                <Outlet />
            ) : (
                <MinimalPaperContainer>
                    <Typography variant='h5'>404</Typography>
                    <Typography variant='subtitle2'>Canal non trouvé.</Typography>
                    <Link component={RouterLink} to="/canaux">Afficher tous les canaux.</Link>
                </MinimalPaperContainer>
            )
        )
    )
}
import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import ContactsIcon from '@mui/icons-material/Contacts';
import CampaignIcon from '@mui/icons-material/Campaign';
import ForumIcon from '@mui/icons-material/Forum';


import {
    Link as RouterLink,
} from "react-router-dom";
import { Build, Event, YouTube } from '@mui/icons-material';

export default function SimpleLinkCard(props) {

    const { link, description, icon, title } = props

    console.log({ link, description, icon, title });

    return (
        <Card sx={{ width: "100%" }} elevation={1}>
            <CardActionArea component={RouterLink} to={link}>
                {icon}
                <CardContent sx={{ textDecoration: "none", width: "100%" }}>
                    <Typography gutterBottom variant="h5" component="div">
                        {title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {description}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

export function ContactsLinkCard() {

    return (
        <SimpleLinkCard title="Contacts"
            link={'/contacts'}
            description="Afficher et gérer les contacts des membres."
            icon={<ContactsIcon color="primary" sx={{ height: 100, width: "100%" }}
            />}
        />
    )
}

export function NotificationLinkCard() {

    return (
        <SimpleLinkCard title="Notifications"
            link={'/notifications'}
            description="Envoyer des notifications aux membres."
            icon={<CampaignIcon color="primary" sx={{ height: 100, width: "100%" }}
            />}
        />
    )
}
export function CanalLinkCard() {

    return (
        <SimpleLinkCard title="Canaux"
            link={'/canaux'}
            description="Créer et gérer des canaux de discussion."
            icon={<ForumIcon color="primary" sx={{ height: 100, width: "100%" }}
            />}
        />
    )
}
export function ProjectsLinkCard() {

    return (
        <SimpleLinkCard title="Chantiers"
            link={'/chantiers'}
            description="Chantiers du président"
            icon={<Build color="primary" sx={{ height: 100, width: "100%" }}
            />}
        />
    )
}
export function EventsLinkCard() {

    return (
        <SimpleLinkCard title="Evenements"
            link={'/evenements'}
            description="Les évènements du parti"
            icon={<Event color="primary" sx={{ height: 100, width: "100%" }}
            />}
        />
    )
}
export function VideosLinkCard() {

    return (
        <SimpleLinkCard title="Vidéos"
            link={'/videos'}
            description="Gérer les vidéos destinées aux membres"
            icon={<YouTube color="primary" sx={{ height: 100, width: "100%" }}
            />}
        />
    )
}


import { Typography } from "@mui/material";
import React from "react";

export default class ErrorBoundary extends React.Component {

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Typography component={"h1"} variant="h4">
          Desolé, une erreur s'est produite.
        </Typography>
      )
    }

    return this.props.children;
  }
}
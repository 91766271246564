import { Position } from "../component/SnakeBar"
import { Error as AppError } from "./Error"

export default class AppAlert extends AppError {
    constructor(message = '', type = 'info', position = null,timer = 5000, isInternal=false) {
        super(0, message, isInternal)
        if (!message) {
            throw new Error("Can't create AppAlert object without message.")
        }
        this.message = message
        this.type = type
        this.timer = timer
        this.isInternal = isInternal
        this.position = position || new Position()
        this.date = (new Date()).toISOString() 
    }
}

export const ALERT_TYPE = {
    error: 'error',
    warning: 'warning',
    info: 'info',
    success: 'success',
}
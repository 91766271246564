
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import { APP_NAME_LONG } from '../core/const'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { alignCenterStyle } from '../style/css/css'

// import logo from '../res/logo192.png'
// export default function Cover(props) {
//     return <Box sx={{ ...alignCenterStyle }}>
//         <img src={logo} alt={APP_NAME_LONG} />
//     </Box>
// }


export function Spinner(props) {

    return (
        <Box sx={{ ...alignCenterStyle }}>
            <CircularProgress sx={{ ...props?.sx}} />
        </Box>
    )
}


export function AdminCover() {
    return <Box sx={{ ...alignCenterStyle }}>
        <AdminPanelSettingsIcon color="primary" sx={{ fontSize: 120 }} />
    </Box>
}
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/auth/Login';
import TopAppBar from './component/TopAppBar';
import { Container } from '@mui/system';
import { Box } from '@mui/material';
import Error404NotFound from './component/fallback/Error404NotFound';
import Message from './pages/messaging/Messaging';
import Canaux from './pages/canal/Canaux';
import Canal from './pages/canal/Canal';
import CanalRouteWraper from './pages/CanalRouteWrapper';
import SharedContacts from './pages/SharedContacts';
import Projects from './pages/project/Projects';
import Videos from './pages/video/Videos';
import ProjectForm from './pages/project/ProjectForm';
import Events from './pages/event/Events';

function Root() {




  return (
    <Box sx={{ backgroundColor: "#e7ebf0", minHeight: "100vh" }}>
      <TopAppBar />
      <main>
        <Container>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/connexion" element={<Login />} />
            <Route path="/videos" element={<Videos />} />
            <Route path="/chantiers" element={<Projects />} />
            <Route path="/chantiers/nouveau" element={<ProjectForm />} />
            <Route path="/evenements" element={<Events />} />
            <Route path="/canaux" element={<CanalRouteWraper />} >
              <Route path="" element={<Canaux />} />
              <Route path=":uid" element={<Canal />} />
            </Route>
            <Route path="/contacts" element={<SharedContacts />} />
            <Route path="/notifications" element={<Message />} />
            <Route path="/*" element={<Error404NotFound />} />
          </Routes>
        </Container>
      </main>
    </Box>
  );
}

export default Root;

import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Groups2, Message } from '@mui/icons-material';
import { Button, ButtonGroup, Card, Divider, Stack } from '@mui/material';
import Contacts from '../Contacts';
import Members from './MembersPanel';
import { child, get, getDatabase, ref, remove, update } from 'firebase/database';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import CanalMenu from '../../component/CanalMenu';
import { CanalContext } from '../../context/CanalContext';
import MessagePanel from './MessagePanel';
import MembersPanel from './MembersPanel';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export default function Canal() {
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box elevation={0}
            sx={{ bgcolor: 'background.paper', }}
        >
            <Tabs
                variant="fullWidth"
                value={value}
                onChange={handleChange}
                aria-label="Canal tabs"
                sx={{ flexGrow: 0, borderRight: 1, borderColor: 'divider' }}
            >
                <Tab icon={<Message />} label="Messages" {...a11yProps(0)} />
                <Tab icon={<Groups2 />} label="Membres" {...a11yProps(2)} />
            </Tabs>
            <CanalTabPanel value={value} index={0}>
                <MessagePanel />
            </CanalTabPanel>
            <CanalTabPanel value={value} index={1}>
                <MembersPanel />
            </CanalTabPanel>
        </Box>
    );
}


function CanalTabPanel(props) {

    const { canalInfos: { name }, refresh } = useContext(CanalContext)
    const navigate = useNavigate()

    return (

        <TabPanel sx={{ flexGrow: 1 }} {...props}>

            <Divider width="100%" textAlign='center' sx={{ my: 2 }}>
                <Typography variant='h6'>{name}</Typography>
                <CanalMenu display={'inline'}
                    onDelete={(result) => {
                        if (result) {
                            navigate(-1, { replace: true })
                        }
                    }}
                    onRename={(newName) => {

                        if (newName) {
                            refresh()
                        }
                    }} />
            </Divider>
            {props.children}
        </TabPanel>
    )

}



export function parseMembersToList(members) {
    let m = []

    if (!members) {
        return m
    }
    const keys = Object.keys(members)


    keys?.forEach((el) => {
        const t = members[el]
        m.push({ ...t, ...{ uid: el } })
    });

    return m
}

export function parseMessagesToList(messages) {
    let m = []

    if (!messages) {
        return m
    }
    const keys = Object.keys(messages)


    keys?.forEach((el) => {
        const t = messages[el]
        m.push({ ...t, ...{ uid: el } })
    });

    return m
}

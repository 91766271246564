import  React, { useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';


export function AppSnakeBar(props) {

    const [isOpen, setOpen] = useState(false);



    useEffect(() => {
        if (!props?.appAlert?.isInternal) {
            setOpen(true)
        }
    }, [props.appAlert])


    if (!props.appAlert) {
        return ""
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    }



    const { timer, message, type, postion } = props.appAlert

    return (
        <Snackbar
            anchorOrigin={postion || new Position()} open={isOpen}
            autoHideDuration={timer || 5000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={type || "info"} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    )
}


// export const POSITION = {
//     topRight: new Position('top','right'),
//     topLeft: new Position('top','left'),
//     topCenter: new Position('top','center'),
//     bottomCenter: new Position('bottom','center'),
//     bottomLeft: new Position('bottom','left'),
//     bottomRight: new Position('bottom','right'),
// }

export class Position {
    constructor(vertical='top', horizontal = 'right') {
        this.vertical= vertical
        this.horizontal = horizontal
    }
}
import { Box, Button, Grid, Paper, Typography } from '@mui/material'
import React, { useEffect } from 'react'

import {
  Link as RouterLink,
} from "react-router-dom";
import { CanalLinkCard, ContactsLinkCard, EventsLinkCard, NotificationLinkCard, ProjectsLinkCard, VideosLinkCard } from '../component/Card';
import { alignCenterStyle } from '../style/css/css'

function Home() {



  useEffect(() => {
    document.title = "Accueil"
  })
  return (
    <>
      <Typography sx={{ mt: 2 }} variant="h4" component="div">
        Accueil
      </Typography>
      <Typography gutterBottom variant="subtitle1" >
        Administration
      </Typography>
      <Grid container spacing={2}>
        <Grid item md={4} sx={{ width: "100%" }} >
          <CanalLinkCard />
        </Grid>
        <Grid item md={4} sx={{ width: "100%" }} >
          <NotificationLinkCard />
        </Grid>
        <Grid item md={4} sx={{ width: "100%" }}>
          <ContactsLinkCard />
        </Grid>
        <Grid item md={4} sx={{ width: "100%" }}>
          <VideosLinkCard />
        </Grid>
        <Grid item md={4} sx={{ width: "100%" }} >
          <ProjectsLinkCard />
        </Grid>
        <Grid item md={4} sx={{ width: "100%" }} >
          <EventsLinkCard />
        </Grid>
      </Grid>
    </>

  )
}

export default Home
import { useState, useEffect, useContext } from "react";

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import Link from '@mui/material/Link';

import {
  Link as RouterLink,
  useLocation,
  useNavigate
} from "react-router-dom";
import { Ci } from "../../core/validation/patern/phonenumber";
import { AppContext } from "../../context/AppContext";
import DomAlert from "../../component/DomAlert";
import { UserContext } from "../../context/UserContext";
import { APP_STATE } from "../../core/kernel";
import AppAlert, { ALERT_TYPE } from "../../core/AppAlert";
import { phoneNumberTransformer } from "../../core/validation/phonevalidator";
import { Typography } from "@mui/material";
import { alignCenterStyle } from "../../style/css/css";



export default function Login() {
  return <Box sx={{ ...alignCenterStyle }}>

    <Box sx={{ width: "450.666px" }}>
      <Typography sx={{ display: 'flex', justifyContent: 'center' }} component={"h1"} variant="h4">
        Conexion
      </Typography>
      <Typography variant="h6" sx={{ display: 'flex'}} >
        Entrez votre numero de téléphone pour vous connecter.
      </Typography>
      <br />
      <LoginManager />
    </Box>
  </Box>
}

function LoginManager() {
  const [componentState, setComponentState] = useState(0);

  const STATE = {
    loginField: 0,
    verifier: 1,
  }


  const defaultLocation = "/"
  let location = useLocation();

  let { origin } = location.state || defaultLocation

  let navigate = useNavigate();

  if (componentState === STATE.loginField) {
    return (
      <LoginFields onClose={() => { setComponentState(STATE.verifier) }} />
    )
  }
  if (componentState === STATE.verifier) {
    return (
      <>
        <NumberVerifier onClose={() => { navigate(origin, { replace: true }) }} />
        <br />
        <Button onClick={() => { setComponentState(STATE.loginField) }}>
          Renvoyer le code
        </Button>
      </>
    )
  }

}

export function LoginFields(props) {

  const [alert, setAlert] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isPending, setIsPending] = useState(false);
  const [isValid, setValidity] = useState(false);




  const { handleAppState } = useContext(AppContext)
  const { currentUser, signInWithPhone, confimPhoneNumber } = useContext(UserContext)



  let login = async () => {
    setAlert(null)

    try {
      signInWithPhone(phoneNumber)
      const { displayName } = currentUser
      handleAppState(APP_STATE.alert, new AppAlert(`Bienvenue ${displayName}`))


    } catch {
      setAlert(new AppAlert("Une erreur s'est produite.", ALERT_TYPE.error))
    }


  };

  const handlePhoneChange = (e) => {

    setAlert(null)

    try {
      const num = phoneNumberTransformer(e.target.value)
      setPhoneNumber(num)
    } catch (error) {
      setPhoneNumber('')
    }
  }
  const handleForm = (e) => {
    e.preventDefault()
    if (isValid) {
      return login()
    }
  }

  return (
    <Box
      sx={{ width: '90%' }}>

      <DomAlert appAlert={alert} />
      <form onSubmit={(e) => handleForm(e)}>
        <TextField id="phonenumber" label="Numero de telephone"
          type="phone"
          pattern={Ci} variant="standard"
          onChange={(e) => {
            handlePhoneChange(e)
          }} value={phoneNumber}
          sx={{ my: 2, width: '100%' }} />
        <br />

        <Button disabled={isPending} type="submit" variant={props.buttonVariant || "contained"} sx={{ width: '100%', my: 2 }}>
          {!isPending ? 'Envoyer le code' : 'SMS en cours d\'envoie...'}</Button>

        <br />
        <Link
          variant="body2"
          underline="hover"
          component={RouterLink} to={'/problemes-de-connexion'}
          color="palette.color.primary" sx={{ fontSize: 'small', my: 2 }}>
          Des soucis pour vous connecter ?
        </Link>

      </form>
    </Box>
  );
}

export function NumberVerifier(props) {

  const [alert, setAlert] = useState(null);
  const [code, setCode] = useState('');
  const [isPending, setIsPending] = useState(false);

  const sendCode = (code) => {
    setIsPending(true)

  }


  useEffect(() => {
    if (code?.length === 6) {
      sendCode(code)
    }
  }, [code])


  return (
    <Paper
      sx={{ minWidth: '320px' }} >
      <Box>
        <DomAlert appAlert={alert} />
        <Box
          sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
          <Box component='h2'>Verification du numero</Box>
        </Box>
        <Box
          sx={{ width: '100%' }}>
          <Typography>
            Entrez le code de vérification que vous avez reçu par SMS.
          </Typography>
          <form onSubmit={(e) => e.preventDefault()}>
            <TextField id="code" label={isPending ? 'Vérification...' : 'Code de vérification'} variant="outlined"
              disabled={isPending}
              type="text" onChange={(e) => {
                const v = e.target.value;
                setCode(v)
              }} value={code}
              sx={{ my: 2, width: '100%' }} />
          </form>
        </Box>
      </Box>
    </Paper>
  )
}
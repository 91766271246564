import { createTheme} from '@mui/material/styles';
import palette from './palette';
import breakpoints from './breakpoints';

const root = {
    backgroundColor:'blue'
}
const AppTheme = createTheme({
            palette,
            root,
            breakpoints,
        })
          
  export default AppTheme; 